import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-template-site',
  templateUrl: './template-site.component.html',
  styleUrls: ['./template-site.component.scss']
})
export class TemplateSiteComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

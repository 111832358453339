import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-alterar-senha',
  templateUrl: './alterar-senha.component.html',
  styleUrls: ['./alterar-senha.component.scss']
})
export class AlterarSenhaComponent implements OnInit {

  oldPassword: string;
  newPassword: string;
  confirmPassword: string;

  constructor(
    private snackBar: MatSnackBar,
    public thisDialogRef: MatDialogRef<AlterarSenhaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
  }

  alterarSenha() {
    if (this.newPassword != this.confirmPassword) {
      var message = 'Senha Nova e Confirmar senha não coincidem!';

      this.snackBar.open(message, 'Ok', {
        duration: 5000,
        panelClass: 'my-snack-bar'
      });

      return;
    }

    this.thisDialogRef.close(this.newPassword);
  }

}

import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {

  carregando = true;
  constructor(
    private translate: TranslateService
   ) { }

  ngOnInit() {
    this.carregando = false;
  }

  mudarPT(){
    this.translate.setDefaultLang('pt-BR');
  }

  mudarEN(){
    this.translate.setDefaultLang('en');
  }

}

import { Injectable } from '@angular/core';
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import { AngularFirestore } from '@angular/fire/firestore';
import { StorageService } from './storage.service';

@Injectable({
    providedIn: 'root'
})

export class RelatorioService {
    data: any[] = [];

    constructor() { }

    gerarPDF(dados, mes, ano, usuario, receita, despesa, total) {
        const mesVerdadeiro = mes + 1;

        const doc = new jsPDF({
            orientation: "landscape"
        })

        autoTable(doc, {
            columnStyles: {
                0: { halign: 'center', fillColor: null, fontStyle: 'bold', fontSize: 14 },
            },
            body: [
                ['Relatório mensal - ' + mesVerdadeiro + '/' + ano],
            ],
        })

        if (usuario.empresa.nome != null) {
            autoTable(doc, {
                columnStyles: {
                    0: { fontStyle: 'bold', fillColor: null },
                    1: { minCellWidth: 50, fillColor: null },
                    2: { fontStyle: 'bold', fillColor: null },
                    3: { minCellWidth: 50, fillColor: null }
                },
                body: [
                    ['Nome da empresa:', usuario.empresa.nome, 'CNPJ da empresa:', usuario.empresa.cnpj],
                    ['E-mail:', usuario.empresa.email, 'Telefone:', usuario.empresa.telefone,],
                ],
            })
        }


        const head = [['Data', 'Categoria', 'Descrição', 'Cliente/Fornecedor', 'Valor', 'Status']]
        var dadosNovo: any;

        dados.forEach((dados, i) => {
            dadosNovo = [
                dados.data.toLocaleDateString(),
                dados.categoria.nome,
                dados.descricao,
                dados.cliente.nome,
                dados.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
                dados.status.name
            ]
            this.data[i] = dadosNovo;
        });

        autoTable(doc, {
            headStyles: { fillColor: '#F1B62B' },
            footStyles: { fillColor: '#F1B62B' },
            head: head,
            body: this.data
        })

        autoTable(doc, {
            tableWidth: 'wrap',
            theme: 'grid',
            columnStyles: {
                0: { fontStyle: 'bold', fillColor: '#F1B62B', lineColor: '#fff', textColor: '#fff' },
                1: { minCellWidth: 50, fillColor: null },
            },
            body: [
                ['Total receita:', receita.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })],
                ['Total despesa:', despesa.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })],
                ['Saldo:', total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })],
            ],
        })

        doc.save('relatorio_mensal_' + mesVerdadeiro + '_' + ano + '.pdf')

    }



}

import { Component, OnInit } from '@angular/core';
import { DadosFixosService } from 'src/app/_service/dados_fixos.service';

import localePt from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
registerLocaleData(localePt);

@Component({
  selector: 'rodape-site',
  templateUrl: './rodape-site.component.html',
  styleUrls: ['./rodape-site.component.scss']
})
export class RodapeSiteComponent implements OnInit {

  ano: any;
  preco: string;

  constructor(
    private dadosFixosService: DadosFixosService
  ) { }

  ngOnInit() {
    const current = new Date();
    this.ano = String(current.getFullYear());

    this.dadosFixosService.getValor().subscribe(resp => this.preco = resp)
  }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class BancosService {

  constructor() { }

  getBancos(): Observable<any> {
    return Observable.create((x) => {
      return x.next([
        {
          "Code": "001",
          "Name": "BANCO DO BRASIL"
        },
        {
          "Code": "341",
          "Name": "ITAÚ"
        },
        {
          "Code": "104",
          "Name": "CAIXA ECONÔMICA FEDERAL"
        },
        {
          "Code": "033",
          "Name": "BANCO SANTANDER S.A."
        },
        {
          "Code": "070",
          "Name": "BRB - BANCO DE BRASÍLIA"
        },
        {
          "Code": "077",
          "Name": "BANCO INTER"
        },
        {
          "Code": "237",
          "Name": "BRADESCO"
        },
        {
          "Code": "745",
          "Name": "CITIBANK"
        },
        {
          "Code": "422",
          "Name": "BANCO SAFRA"
        },
        {
          "Code": "399",
          "Name": "BANCO HSBC"
        },
        {
          "Code": "756",
          "Name": "BANCOOB"
        },
        {
          "Code": "212",
          "Name": "BANCO ORIGINAL"
        },
        {
          "Code": "002",
          "Name": "BANCO CENTRAL DO BRASIL"
        },
        {
          "Code": "003",
          "Name": "BANCO DA AMAZONIA S.A"
        },
        {
          "Code": "004",
          "Name": "BANCO DO NORDESTE DO BRASIL S.A"
        },
        {
          "Code": "007",
          "Name": "BANCO NAC DESENV. ECO. SOCIAL S.A"
        },
        {
          "Code": "008",
          "Name": "BANCO MERIDIONAL DO BRASIL"
        },
        {
          "Code": "020",
          "Name": "BANCO DO ESTADO DE ALAGOAS S.A"
        },
        {
          "Code": "021",
          "Name": "BANCO DO ESTADO DO ESPIRITO SANTO S.A"
        },
        {
          "Code": "022",
          "Name": "BANCO DE CREDITO REAL DE MINAS GERAIS SA"
        },
        {
          "Code": "024",
          "Name": "BANCO DO ESTADO DE PERNAMBUCO"
        },
        {
          "Code": "025",
          "Name": "BANCO ALFA S/A"
        },
        {
          "Code": "026",
          "Name": "BANCO DO ESTADO DO ACRE S.A"
        },
        {
          "Code": "027",
          "Name": "BANCO DO ESTADO DE SANTA CATARINA S.A"
        },
        {
          "Code": "028",
          "Name": "BANCO DO ESTADO DA BAHIA S.A"
        },
        {
          "Code": "029",
          "Name": "BANCO DO ESTADO DO RIO DE JANEIRO S.A"
        },
        {
          "Code": "030",
          "Name": "BANCO DO ESTADO DA PARAIBA S.A"
        },
        {
          "Code": "031",
          "Name": "BANCO DO ESTADO DE GOIAS S.A"
        },
        {
          "Code": "032",
          "Name": "BANCO DO ESTADO DO MATO GROSSO S.A."
        },
        {
          "Code": "034",
          "Name": "BANCO DO ESADO DO AMAZONAS S.A"
        },
        {
          "Code": "035",
          "Name": "BANCO DO ESTADO DO CEARA S.A"
        },
        {
          "Code": "036",
          "Name": "BANCO DO ESTADO DO MARANHAO S.A"
        },
        {
          "Code": "037",
          "Name": "BANCO DO ESTADO DO PARA S.A"
        },
        {
          "Code": "038",
          "Name": "BANCO DO ESTADO DO PARANA S.A"
        },
        {
          "Code": "039",
          "Name": "BANCO DO ESTADO DO PIAUI S.A"
        },
        {
          "Code": "041",
          "Name": "BANCO DO ESTADO DO RIO GRANDE DO SUL S.A"
        },
        {
          "Code": "047",
          "Name": "BANCO DO ESTADO DE SERGIPE S.A"
        },
        {
          "Code": "048",
          "Name": "BANCO DO ESTADO DE MINAS GERAIS S.A"
        },
        {
          "Code": "059",
          "Name": "BANCO DO ESTADO DE RONDONIA S.A"
        },
        {
          "Code": "106",
          "Name": "BANCO ITABANCO S.A."
        },
        {
          "Code": "107",
          "Name": "BANCO BBM S.A"
        },
        {
          "Code": "109",
          "Name": "BANCO CREDIBANCO S.A"
        },
        {
          "Code": "116",
          "Name": "BANCO B.N.L DO BRASIL S.A"
        },
        {
          "Code": "148",
          "Name": "MULTI BANCO S.A"
        },
        {
          "Code": "151",
          "Name": "CAIXA ECONOMICA DO ESTADO DE SAO PAULO"
        },
        {
          "Code": "153",
          "Name": "CAIXA ECONOMICA DO ESTADO DO R.G.SUL"
        },
        {
          "Code": "165",
          "Name": "BANCO NORCHEM S.A"
        },
        {
          "Code": "166",
          "Name": "BANCO INTER-ATLANTICO S.A"
        },
        {
          "Code": "168",
          "Name": "BANCO C.C.F. BRASIL S.A"
        },
        {
          "Code": "175",
          "Name": "CONTINENTAL BANCO S.A"
        },
        {
          "Code": "184",
          "Name": "BBA - CREDITANSTALT S.A"
        },
        {
          "Code": "199",
          "Name": "BANCO FINANCIAL PORTUGUES"
        },
        {
          "Code": "200",
          "Name": "BANCO FRICRISA AXELRUD S.A"
        },
        {
          "Code": "201",
          "Name": "BANCO AUGUSTA INDUSTRIA E COMERCIAL S.A"
        },
        {
          "Code": "204",
          "Name": "BANCO S.R.L S.A"
        },
        {
          "Code": "205",
          "Name": "BANCO SUL AMERICA S.A"
        },
        {
          "Code": "206",
          "Name": "BANCO MARTINELLI S.A"
        },
        {
          "Code": "208",
          "Name": "BANCO PACTUAL S.A"
        },
        {
          "Code": "210",
          "Name": "DEUTSCH SUDAMERIKANICHE BANK AG"
        },
        {
          "Code": "211",
          "Name": "BANCO SISTEMA S.A"
        },
        {
          "Code": "213",
          "Name": "BANCO ARBI S.A"
        },
        {
          "Code": "214",
          "Name": "BANCO DIBENS S.A"
        },
        {
          "Code": "215",
          "Name": "BANCO AMERICA DO SUL S.A"
        },
        {
          "Code": "216",
          "Name": "BANCO REGIONAL MALCON S.A"
        },
        {
          "Code": "217",
          "Name": "BANCO AGROINVEST S.A"
        },
        {
          "Code": "218",
          "Name": "BS2"
        },
        {
          "Code": "219",
          "Name": "BANCO DE CREDITO DE SAO PAULO S.A"

        },
        {
          "Code": "220",
          "Name": "BANCO CREFISUL"
        },
        {
          "Code": "221",
          "Name": "BANCO GRAPHUS S.A"
        },
        {
          "Code": "222",
          "Name": "BANCO AGF BRASIL S. A."
        },
        {
          "Code": "223",
          "Name": "BANCO INTERUNION S.A"
        },
        {
          "Code": "224",
          "Name": "BANCO FIBRA S.A"
        },
        {
          "Code": "225",
          "Name": "BANCO BRASCAN S.A"
        },
        {
          "Code": "228",
          "Name": "BANCO ICATU S.A"
        },
        {
          "Code": "229",
          "Name": "BANCO CRUZEIRO S.A"
        },
        {
          "Code": "230",
          "Name": "BANCO BANDEIRANTES S.A"
        },
        {
          "Code": "231",
          "Name": "BANCO BOAVISTA S.A"
        },
        {
          "Code": "232",
          "Name": "BANCO INTERPART S.A"
        },
        {
          "Code": "233",
          "Name": "BANCO MAPPIN S.A"
        },
        {
          "Code": "234",
          "Name": "BANCO LAVRA S.A."
        },
        {
          "Code": "235",
          "Name": "BANCO LIBERAL S.A"
        },
        {
          "Code": "236",
          "Name": "BANCO CAMBIAL S.A"
        },
        {
          "Code": "239",
          "Name": "BANCO BANCRED S.A"
        },
        {
          "Code": "240",
          "Name": "BANCO DE CREDITO REAL DE MINAS GERAIS S."
        },
        {
          "Code": "241",
          "Name": "BANCO CLASSICO S.A"
        },
        {
          "Code": "242",
          "Name": "BANCO EUROINVEST S.A"
        },
        {
          "Code": "243",
          "Name": "BANCO MÁXIMA S.A"
        },
        {
          "Code": "244",
          "Name": "BANCO CIDADE S.A"

        },
        {
          "Code": "245",
          "Name": "BANCO EMPRESARIAL S.A"
        },
        {
          "Code": "246",
          "Name": "BANCO ABC ROMA S.A"
        },
        {
          "Code": "247",
          "Name": "BANCO OMEGA S.A"
        },
        {
          "Code": "249",
          "Name": "BANCO INVESTCRED S.A"
        },
        {
          "Code": "250",
          "Name": "BANCO SCHAHIN CURY S.A"

        },
        {
          "Code": "251",
          "Name": "BANCO SAO JORGE S.A."

        },
        {
          "Code": "252",
          "Name": "BANCO FININVEST S.A"

        },
        {
          "Code": "254",
          "Name": "BANCO PARANA BANCO S.A"
        },
        {
          "Code": "255",
          "Name": "MILBANCO S.A.",
        },
        {
          "Code": "256",
          "Name": "BANCO GULVINVEST S.A"


        },
        {
          "Code": "258",
          "Name": "BANCO INDUSCRED S.A"
        },
        {
          "Code": "261",
          "Name": "BANCO VARIG S.A"
        },
        {
          "Code": "262",
          "Name": "BANCO BOREAL S.A"
        },
        {
          "Code": "263",
          "Name": "BANCO CACIQUE"
        },
        {
          "Code": "264",
          "Name": "BANCO PERFORMANCE S.A"
        },
        {
          "Code": "265",
          "Name": "BANCO FATOR S.A"
        },
        {
          "Code": "266",
          "Name": "BANCO CEDULA S.A"
        },
        {
          "Code": "267",
          "Name": "BANCO BBM-COM.C.IMOB.CFI S.A."
        },
        {
          "Code": "275",
          "Name": "BANCO REAL S.A"
        },
        {
          "Code": "277",
          "Name": "BANCO PLANIBANC S.A"
        },
        {
          "Code": "282",
          "Name": "BANCO BRASILEIRO COMERCIAL"
        },
        {
          "Code": "291",
          "Name": "BANCO DE CREDITO NACIONAL S.A"
        },
        {
          "Code": "294",
          "Name": "BCR - BANCO DE CREDITO REAL S.A"
        },
        {
          "Code": "295",
          "Name": "BANCO CREDIPLAN S.A"
        },
        {
          "Code": "300",
          "Name": "BANCO DE LA NACION ARGENTINA S.A"
        },
        {
          "Code": "302",
          "Name": "BANCO DO PROGRESSO S.A"
        },
        {
          "Code": "303",
          "Name": "BANCO HNF S.A."
        },
        {
          "Code": "304",
          "Name": "BANCO PONTUAL S.A"
        },
        {
          "Code": "308",
          "Name": "BANCO COMERCIAL BANCESA S.A."
        },
        {
          "Code": "318",
          "Name": "BANCO B.M.G. S.A"
        },
        {
          "Code": "320",
          "Name": "BANCO INDUSTRIAL E COMERCIAL"
        },
        {
          "Code": "346",
          "Name": "BANCO FRANCES E BRASILEIRO S.A"
        },
        {
          "Code": "347",
          "Name": "BANCO SUDAMERIS BRASIL S.A"

        },
        {
          "Code": "351",
          "Name": "BANCO BOZANO SIMONSEN S.A"

        },
        {
          "Code": "353",
          "Name": "BANCO GERAL DO COMERCIO S.A"

        },
        {
          "Code": "356",
          "Name": "ABN AMRO S.A"

        },
        {
          "Code": "366",
          "Name": "BANCO SOGERAL S.A"

        },
        {
          "Code": "369",
          "Name": "PONTUAL"

        },
        {
          "Code": "370",
          "Name": "BEAL - BANCO EUROPEU PARA AMERICA LATINA"

        },
        {
          "Code": "372",
          "Name": "BANCO ITAMARATI S.A"

        },
        {
          "Code": "375",
          "Name": "BANCO FENICIA S.A"

        },
        {
          "Code": "376",
          "Name": "CHASE MANHATTAN BANK S.A"

        },
        {
          "Code": "388",
          "Name": "BANCO MERCANTIL DE DESCONTOS S/A"

        },
        {
          "Code": "389",
          "Name": "BANCO MERCANTIL DO BRASIL S.A"

        },
        {
          "Code": "392",
          "Name": "BANCO MERCANTIL DE SAO PAULO S.A"

        },
        {
          "Code": "394",
          "Name": "BANCO B.M.C. S.A"

        },
        {
          "Code": "409",
          "Name": "UNIBANCO - UNIAO DOS BANCOS BRASILEIROS"

        },
        {
          "Code": "412",
          "Name": "BANCO NACIONAL DA BAHIA S.A"

        },
        {
          "Code": "415",
          "Name": "BANCO NACIONAL S.A"

        },
        {
          "Code": "420",
          "Name": "BANCO NACIONAL DO NORTE S.A"

        },
        {
          "Code": "424",
          "Name": "BANCO NOROESTE S.A"

        },
        {
          "Code": "434",
          "Name": "BANCO FORTALEZA S.A"

        },
        {
          "Code": "453",
          "Name": "BANCO RURAL S.A"

        },
        {
          "Code": "456",
          "Name": "BANCO TOKIO S.A"

        },
        {
          "Code": "464",
          "Name": "BANCO SUMITOMO BRASILEIRO S.A"

        },
        {
          "Code": "466",
          "Name": "BANCO MITSUBISHI BRASILEIRO S.A"

        },
        {
          "Code": "472",
          "Name": "LLOYDS BANK PLC"

        },
        {
          "Code": "473",
          "Name": "BANCO FINANCIAL PORTUGUES S.A"

        },
        {
          "Code": "477",
          "Name": "CITIBANK N.A"

        },
        {
          "Code": "479",
          "Name": "BANCO DE BOSTON S.A"

        },
        {
          "Code": "480",
          "Name": "BANCO PORTUGUES DO ATLANTICO-BRASIL S.A"

        },
        {
          "Code": "483",
          "Name": "BANCO AGRIMISA S.A."

        },
        {
          "Code": "487",
          "Name": "DEUTSCHE BANK S.A - BANCO ALEMAO"

        },
        {
          "Code": "488",
          "Name": "BANCO J. P. MORGAN S.A"

        },
        {
          "Code": "489",
          "Name": "BANESTO BANCO URUGAUAY S.A"

        },
        {
          "Code": "492",
          "Name": "INTERNATIONALE NEDERLANDEN BANK N.V."

        },
        {
          "Code": "493",
          "Name": "BANCO UNION S.A.C.A"

        },
        {
          "Code": "494",
          "Name": "BANCO LA REP. ORIENTAL DEL URUGUAY"

        },
        {
          "Code": "495",
          "Name": "BANCO LA PROVINCIA DE BUENOS AIRES"

        },
        {
          "Code": "496",
          "Name": "BANCO EXTERIOR DE ESPANA S.A"

        },
        {
          "Code": "498",
          "Name": "CENTRO HISPANO BANCO"

        },
        {
          "Code": "499",
          "Name": "BANCO IOCHPE S.A"

        },
        {
          "Code": "501",
          "Name": "BANCO BRASILEIRO IRAQUIANO S.A."

        },
        {
          "Code": "502",
          "Name": "BANCO SANTANDER DE NEGOCIOS S.A"

        },
        {
          "Code": "504",
          "Name": "BANCO MULTIPLIC S.A"

        },
        {
          "Code": "505",
          "Name": "BANCO GARANTIA S.A"

        },
        {
          "Code": "600",
          "Name": "BANCO LUSO BRASILEIRO S.A"

        },
        {
          "Code": "601",
          "Name": "BFC BANCO S.A."

        },
        {
          "Code": "602",
          "Name": "BANCO PATENTE S.A"

        },
        {
          "Code": "604",
          "Name": "BANCO INDUSTRIAL DO BRASIL S.A"

        },
        {
          "Code": "607",
          "Name": "BANCO SANTOS NEVES S.A"

        },
        {
          "Code": "608",
          "Name": "BANCO OPEN S.A"

        },
        {
          "Code": "610",
          "Name": "BANCO V.R. S.A"

        },
        {
          "Code": "611",
          "Name": "BANCO PAULISTA S.A"

        },
        {
          "Code": "612",
          "Name": "BANCO GUANABARA S.A"

        },
        {
          "Code": "613",
          "Name": "BANCO PECUNIA S.A"

        },
        {
          "Code": "616",
          "Name": "BANCO INTERPACIFICO S.A"

        },
        {
          "Code": "617",
          "Name": "BANCO INVESTOR S.A."

        },
        {
          "Code": "618",
          "Name": "BANCO TENDENCIA S.A"

        },
        {
          "Code": "621",
          "Name": "BANCO APLICAP S.A."

        },
        {
          "Code": "622",
          "Name": "BANCO DRACMA S.A"

        },
        {
          "Code": "623",
          "Name": "BANCO PANAMERICANO S.A"

        },
        {
          "Code": "624",
          "Name": "BANCO GENERAL MOTORS S.A"

        },
        {
          "Code": "625",
          "Name": "BANCO ARAUCARIA S.A"

        },
        {
          "Code": "626",
          "Name": "BANCO FICSA S.A"

        },
        {
          "Code": "627",
          "Name": "BANCO DESTAK S.A"

        },
        {
          "Code": "628",
          "Name": "BANCO CRITERIUM S.A"

        },
        {
          "Code": "629",
          "Name": "BANCORP BANCO COML. E. DE INVESTMENTO"

        },
        {
          "Code": "630",
          "Name": "BANCO INTERCAP S.A"

        },
        {
          "Code": "633",
          "Name": "BANCO REDIMENTO S.A"

        },
        {
          "Code": "634",
          "Name": "BANCO TRIANGULO S.A"

        },
        {
          "Code": "635",
          "Name": "BANCO DO ESTADO DO AMAPA S.A"

        },
        {
          "Code": "637",
          "Name": "BANCO SOFISA S.A"

        },
        {
          "Code": "638",
          "Name": "BANCO PROSPER S.A"

        },
        {
          "Code": "639",
          "Name": "BIG S.A. - BANCO IRMAOS GUIMARAES"

        },
        {
          "Code": "640",
          "Name": "BANCO DE CREDITO METROPOLITANO S.A"

        },
        {
          "Code": "641",
          "Name": "BANCO EXCEL ECONOMICO S/A"

        },
        {
          "Code": "643",
          "Name": "BANCO SEGMENTO S.A"

        },
        {
          "Code": "645",
          "Name": "BANCO DO ESTADO DE RORAIMA S.A"

        },
        {
          "Code": "647",
          "Name": "BANCO MARKA S.A"

        },
        {
          "Code": "648",
          "Name": "BANCO ATLANTIS S.A"

        },
        {
          "Code": "649",
          "Name": "BANCO DIMENSAO S.A"

        },
        {
          "Code": "650",
          "Name": "BANCO PEBB S.A"

        },
        {
          "Code": "652",
          "Name": "BANCO FRANCES E BRASILEIRO SA"

        },
        {
          "Code": "653",
          "Name": "BANCO INDUSVAL S.A"

        },
        {
          "Code": "654",
          "Name": "BANCO A. J. RENNER S.A"

        },
        {
          "Code": "655",
          "Name": "BANCO VOTORANTIM S.A."

        },
        {
          "Code": "656",
          "Name": "BANCO MATRIX S.A"

        },
        {
          "Code": "657",
          "Name": "BANCO TECNICORP S.A"

        },
        {
          "Code": "658",
          "Name": "BANCO PORTO REAL S.A"

        },
        {
          "Code": "702",
          "Name": "BANCO SANTOS S.A"

        },
        {
          "Code": "705",
          "Name": "BANCO INVESTCORP S.A."

        },
        {
          "Code": "707",
          "Name": "BANCO DAYCOVAL S.A"

        },
        {
          "Code": "711",
          "Name": "BANCO VETOR S.A."

        },
        {
          "Code": "713",
          "Name": "BANCO CINDAM S.A"

        },
        {
          "Code": "715",
          "Name": "BANCO VEGA S.A"

        },
        {
          "Code": "718",
          "Name": "BANCO OPERADOR S.A"

        },
        {
          "Code": "719",
          "Name": "BANCO PRIMUS S.A"

        },
        {
          "Code": "720",
          "Name": "BANCO MAXINVEST S.A"

        },
        {
          "Code": "721",
          "Name": "BANCO CREDIBEL S.A"

        },
        {
          "Code": "722",
          "Name": "BANCO INTERIOR DE SAO PAULO S.A"

        },
        {
          "Code": "724",
          "Name": "BANCO PORTO SEGURO S.A"

        },
        {
          "Code": "725",
          "Name": "BANCO FINABANCO S.A"

        },
        {
          "Code": "726",
          "Name": "BANCO UNIVERSAL S.A"

        },
        {
          "Code": "728",
          "Name": "BANCO FITAL S.A"

        },
        {
          "Code": "729",
          "Name": "BANCO FONTE S.A"

        },
        {
          "Code": "730",
          "Name": "BANCO COMERCIAL PARAGUAYO S.A"

        },
        {
          "Code": "731",
          "Name": "BANCO GNPP S.A."

        },
        {
          "Code": "732",
          "Name": "BANCO PREMIER S.A."

        },
        {
          "Code": "733",
          "Name": "BANCO NACOES S.A."

        },
        {
          "Code": "734",
          "Name": "BANCO GERDAU S.A"

        },
        {
          "Code": "735",
          "Name": "BANCO NEON"
        },
        {
          "Code": "736",
          "Name": "BANCO UNITED S.A"

        },
        {
          "Code": "737",
          "Name": "THECA"

        },
        {
          "Code": "738",
          "Name": "MARADA"

        },
        {
          "Code": "739",
          "Name": "BGN"

        },
        {
          "Code": "740",
          "Name": "BCN BARCLAYS"

        },
        {
          "Code": "741",
          "Name": "BRP"

        },
        {
          "Code": "742",
          "Name": "EQUATORIAL"

        },
        {
          "Code": "743",
          "Name": "BANCO EMBLEMA S.A"

        },
        {
          "Code": "744",
          "Name": "THE FIRST NATIONAL BANK OF BOSTON"

        },
        {
          "Code": "746",
          "Name": "MODAL S.A."

        },
        {
          "Code": "747",
          "Name": "RABOBANK DO BRASIL"
        },
        {
          "Code": "748",
          "Name": "SICREDI"

        },
        {
          "Code": "749",
          "Name": "BRMSANTIL SA"

        },
        {
          "Code": "750",
          "Name": "BANCO REPUBLIC NATIONAL OF NEW YORK (BRA"

        },
        {
          "Code": "751",
          "Name": "DRESDNER BANK LATEINAMERIKA-BRASIL S/A"

        },
        {
          "Code": "752",
          "Name": "BANCO BANQUE NATIONALE DE PARIS BRASIL S"

        },
        {
          "Code": "753",
          "Name": "BANCO COMERCIAL URUGUAI S.A."

        },
        {
          "Code": "755",
          "Name": "BANCO MERRILL LYNCH S.A"

        },
        {
          "Code": "757",
          "Name": "BANCO KEB DO BRASIL S.A."

        },
        {
          "Code": "260",
          "Name": "Nu Pagamentos S.A"
        },
        {
          "Code": "102",
          "Name": "XP INVESTIMENTOS"

        },
        {
          "Code": "336",
          "Name": "BANCO C6 S.A."

        },
        {
          "Code": "290",
          "Name": "PagSeguro Internet S.A."

        },
        {
          "Code": "323",
          "Name": "MercadoPago.com Representações Ltda."

        },
        {
          "Code": "332",
          "Name": "Acesso Soluções de Pagamento S.A."
        },
        {
          "Code": "325",
          "Name": "Órama DTVM S.A."

        },
        {
          "Code": "85",
          "Name": "COOPERATIVA CENTRAL DE CREDITO - AILOS"

        },
        {
          "Code": "125",
          "Name": "PLURAL S.A. BANCO MULTIPLO"

        },
        {
          "Code": "197",
          "Name": "STONE PAGAMENTOS S.A."

        }
      ])
    });
  }


}

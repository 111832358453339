import {Injectable} from '@angular/core';
import {environment} from './environments/environment';

@Injectable()
export class config {

  static obterUrlAPI(): string {
    return `${environment.wp_link}/blog-wp/wp-json/wp/v2/`;
  }

  static caminhoUpload(): string {
    return `${environment.wp_link}/blog-wp/wp-content/uploads/`;
  }

}

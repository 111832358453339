import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ClientesFornecedoresService } from 'src/app/_service/clientes_fornecedores.service';
import { BancosService } from 'src/app/_service/bancos.service';

@Component({
  selector: 'app-adicionar-cliente-fornecedor',
  templateUrl: './adicionar-cliente-fornecedor.component.html',
  styleUrls: ['./adicionar-cliente-fornecedor.component.scss']
})
export class AdicionarClienteFornecedorComponent implements OnInit {

  bancos: any[];

  public clienteFornecedorForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private bancosService: BancosService,
    private clientesFornecedoresService: ClientesFornecedoresService,
    public thisDialogRef: MatDialogRef<AdicionarClienteFornecedorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.getBanco();

    this.clienteFornecedorForm = this.formBuilder.group({
      nome: [null, Validators.required],
      telefone: [null],
      cnpj_cpf: [null],
      nome_titular: [null],
      banco: [null],
      agencia: [null],
      conta: [null],
    });
  }

  adicionarCliente() {
    this.thisDialogRef.close(this.clienteFornecedorForm.value);
  }

  getBanco() {
    this.bancosService.getBancos().subscribe(x => {
      this.bancos = x
    }, error => {
      console.log(error)
    });
  }

}

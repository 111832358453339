import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { StorageService } from 'src/app/_service/storage.service';
import { PagarmeService } from 'src/app/_service/pagarme.service';
import { AuthService } from 'src/app/_service/auth.service';
import { MatDialog } from '@angular/material';
import { RenovarAssinaturaComponent } from '../../renovar-assinatura/renovar-assinatura.component';

@Component({
  selector: 'plano-assinatura',
  templateUrl: './plano-assinatura.component.html',
  styleUrls: ['./plano-assinatura.component.scss']
})
export class PlanoAssinaturaComponent implements OnInit {

  data: any = {
    usuario: {},
    empresa: {},
  };
  historico: any;
  planoUsuario: any;
  valueUid: string;

  constructor(
    private afs: AngularFirestore,
    private storageService: StorageService,
    public dialog: MatDialog,
    private authService: AuthService,
    private pagarmeService: PagarmeService
  ) {
    this.valueUid = this.storageService.obterUid();
    this.verificarPagamentos();
  }

  ngOnInit() {
    this.getDadosUsuario();
    this.getPlanoUsuario();
  }

  listagemPagamento: any;

  verificarPagamentos() {
    this.afs.collection('users').doc(this.valueUid).collection('pagamento').valueChanges().subscribe(resp => {
      this.listagemPagamento = resp;
      this.listagemPagamento.reverse();

      this.listagemPagamento.forEach(pgto => {
        if (pgto.transaction_id) {
          this.pagarmeService.verificarTransacao(pgto.transaction_id).subscribe(resp => {

            if (resp.status == "waiting_payment") {
              pgto.notafiscal = 'Aguardando pagamento'
            } else if (resp.status == "paid") {
              pgto.notafiscal = 'Pago'
            }

          }, error => {
            console.log(error)
          })
        }
      });

    }, error => {
      this.authService.errorFirebase(error);
      console.log(error)
    })
  }

  async getDadosUsuario() {
    this.afs.collection('users').doc(this.valueUid).collection('geralUsuario').doc('usuario').valueChanges().subscribe(resp => {
      this.data = resp;
    }, error => {
      this.authService.errorFirebase(error);
      console.log(error)
    })
  }

  getPlanoUsuario() {
    this.planoUsuario = {
      nome: 'Mensal',
      vencimento: 'Thu Sep 10 2020 08:58:21 GMT-0300',
    };

    // this.afs.collection('users').doc(this.valueUid).collection('geralUsuario').doc('plano').valueChanges().subscribe(resp => {
    //   this.planoUsuario = resp;
    // })
  }



  renovarAssinatura() {
    const dialogRef = this.dialog.open(RenovarAssinaturaComponent, {
      panelClass: 'modalClassRenovar'
    });
    dialogRef.afterClosed();
  }

}

import { Component, OnInit } from '@angular/core';
import { DadosFixosService } from '../_service/dados_fixos.service';
import { PagarmeService } from '../_service/pagarme.service';
import { MatDialog } from '@angular/material';
import { StorageService } from '../_service/storage.service';
import { AngularFirestore } from '@angular/fire/firestore';
import Swal from 'sweetalert2';
import { FormaPagamentoComponent } from '../forma-pagamento/forma-pagamento.component';

@Component({
  selector: 'app-tabela-preco',
  templateUrl: './tabela-preco.component.html',
  styleUrls: ['./tabela-preco.component.scss']
})
export class TabelaPrecoComponent implements OnInit {

  preco_mensal: any;
  preco_semestral: any;
  preco_anual: any;

  preco_normal_semestral: any;
  preco_normal_anual: any;

  preco_desconto_semestral: any;
  preco_desconto_anual: any;

  valueUid: string;
  vencimento: any;

  constructor(
    private dadosFixosService: DadosFixosService,
    private pagarmeService: PagarmeService,
    public dialog: MatDialog,
    private storageService: StorageService,
    private afs: AngularFirestore
  ) {
    this.valueUid = this.storageService.obterUid();
  }

  ngOnInit() {

    this.dadosFixosService.getValor().subscribe(resp => {

      const preco10 = resp * 0.9;
      const preco15 = resp * 0.85;

      const totalSemestral10 = preco10 * 6;
      const totalAnual15 = preco15 * 12;

      this.preco_mensal = resp;
      this.preco_semestral = preco10;
      this.preco_anual = preco15;

      this.preco_normal_semestral = resp * 6;
      this.preco_normal_anual = resp * 12;

      this.preco_desconto_semestral = totalSemestral10;
      this.preco_desconto_anual = totalAnual15;

    })
  }

  assinar(valor, qtdd, plano) {
    if (this.valueUid) {
      this.modalFormaPagamento(valor, qtdd, plano);
    } else {
      Swal.fire({
        confirmButtonColor: '#F1B62B',
        title: 'Você precisa estar logado para adquirir uma assinatura',
        html: '<a class="aModal" href="/login">Ir para o Login</a>'
      })
    }
  }

  modalFormaPagamento(valor, qtdd, plano) {
    const dialogRef = this.dialog.open(FormaPagamentoComponent, {
      data: {
        valor: valor,
        qtdd: qtdd,
      },
      panelClass: 'modalClassAdicionar'
    });

    dialogRef.afterClosed().subscribe(data => {

      if (!data) {
        return
      }

      const current = new Date();
      const dataAtual = current.getTime();

      this.pagarmeService.transacao(data).subscribe(resp => {       

        if (data.payment_method == 'boleto') {
          // operação de boleto

          this.afs.collection("users").doc(this.valueUid).collection("pagamento").doc(String(dataAtual)).set({
            data: current.getTime(),
            plano: plano,
            forma_pagamento: 'Boleto',
            valor: valor * qtdd,
            nota_fiscal: 'Aguardando pagamento',
            transaction_id: resp.id
          });

          Swal.fire({
            confirmButtonColor: '#F1B62B',
            icon: 'success',
            title: 'Baixe o boleto',
            html: '<a class="aModal" href="' + resp.boleto_url + '" target="_blank">Clique para baixar</a>'
          })

        } else {
          // operação de cartão de crédito

          this.afs.collection("users").doc(this.valueUid).collection("pagamento").doc(String(dataAtual)).set({
            data: dataAtual,
            plano: plano,
            forma_pagamento: 'Cartão de crédito',
            valor: valor * qtdd,
            nota_fiscal: '',
            transaction_id: resp.id
          });

          // atualiza a data de vencimento
          this.afs.collection('users').doc(this.valueUid).collection('geralUsuario').doc('usuario').valueChanges().subscribe(resp => {
            this.vencimento = resp;
            this.vencimento = new Date(this.vencimento.usuario.expira_assinatura);
            this.vencimento.setMonth(this.vencimento.getMonth() + qtdd);

            this.afs.collection('users').doc(this.valueUid).collection('geralUsuario').doc('usuario').update({
              expira_assinatura: this.vencimento,
              tipo_plano: plano
            })

            Swal.fire({
              confirmButtonColor: '#F1B62B',
              icon: 'success',
              title: 'Pagamento realizado com sucesso!'
            })

          }, error => {
            console.log(error);
          })

        }
      })

    }, error => {
      console.log(error);
      Swal.fire({
        confirmButtonColor: '#F1B62B',
        icon: 'error',
        title: 'Aconteceu algo de errado!',
        text: 'Seu pagamento não foi efetuado.'
      })
    });
  }

}

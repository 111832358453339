import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TemplatePadraoComponent } from './template-padrao/template-padrao.component';
import { Page404Component } from './page404/page404.component';
import { AuthGuard } from './_guards/auth.guard';
import { TemplateSiteComponent } from './template-site/template-site.component';
import { LoginComponent } from './sistema_web/login/login.component';
import { NovoUsuarioComponent } from './sistema_web/novo-usuario/novo-usuario.component';
import { RedefinirSenhaComponent } from './sistema_web/redefinir-senha/redefinir-senha.component';
import { IndexComponent } from './site/index/index.component';
import { PoliticaPrivacidadeComponent } from './site/politica-privacidade/politica-privacidade.component';
import { TermosUsoComponent } from './site/termos-uso/termos-uso.component';
import { BlogComponent } from './site/blog/blog.component';
import { RegistrarReceitaComponent } from './sistema_web/registrar-receita/registrar-receita.component';
import { RegistrarDespesaComponent } from './sistema_web/registrar-despesa/registrar-despesa.component';
import { PerfilUsuarioComponent } from './sistema_web/perfil-usuario/perfil-usuario.component';
import { FluxoCaixaComponent } from './sistema_web/fluxo-caixa/fluxo-caixa.component';
import { ClientesComponent } from './sistema_web/clientes/clientes.component';
import { FornecedoresComponent } from './sistema_web/fornecedores/fornecedores.component';
import { CategoriaComponent } from './sistema_web/categoria/categoria.component';
import { ContatoComponent } from './site/contato/contato.component';
import { PrecosComponent } from './site/precos/precos.component';



const routes: Routes = [

  { path: 'login', component: LoginComponent, },
  { path: 'novo-usuario', component: NovoUsuarioComponent },
  { path: 'redefinir-senha', component: RedefinirSenhaComponent },

  {
    path: '',
    component: TemplateSiteComponent,
    children: [
      {
        path: '',
        component: IndexComponent,
        pathMatch: 'full',
      }, {
        path: 'politica-de-privacidade',
        component: PoliticaPrivacidadeComponent
      }, {
        path: 'termos-de-uso',
        component: TermosUsoComponent
      }, {
        path: 'contato',
        component: ContatoComponent
      },{
        path: 'precos',
        component: PrecosComponent
      }, {
        path: 'blog',
        loadChildren: () => import('./site/blog/blog.module').then(m => m.BlogModule),
        component: BlogComponent,       
      },


    ],
  },

  {
    path: '',
    component: TemplatePadraoComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'registrar-receita',
        component: RegistrarReceitaComponent
      }, {
        path: 'registrar-despesa',
        component: RegistrarDespesaComponent
      }, {
        path: 'perfil-usuario',
        component: PerfilUsuarioComponent
      }, {
        path: 'fluxo-caixa',
        component: FluxoCaixaComponent,
        pathMatch: 'full',
      }, {
        path: 'clientes',
        component: ClientesComponent
      }, {
        path: 'fornecedores',
        component: FornecedoresComponent
      }, {
        path: 'categorias',
        component: CategoriaComponent
      },
    ]
  },

  { path: '**', component: Page404Component },


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-perfil-usuario',
  templateUrl: './perfil-usuario.component.html',
  styleUrls: ['./perfil-usuario.component.scss']
})
export class PerfilUsuarioComponent implements OnInit {

  abaDadosPessoais = true;
  abaPlano = false;

  constructor() { }

  ngOnInit() {
  }


  alteraAba() {
    this.abaDadosPessoais = !this.abaDadosPessoais;
    this.abaPlano = !this.abaPlano;
  }
}

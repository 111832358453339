import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatSnackBar } from '@angular/material';
import { DadosFixosService } from 'src/app/_service/dados_fixos.service';
import { StorageService } from 'src/app/_service/storage.service';
import { AuthService } from 'src/app/_service/auth.service';
@Component({
  selector: 'app-registrar-despesa',
  templateUrl: './registrar-despesa.component.html',
  styleUrls: ['./registrar-despesa.component.scss']
})
export class RegistrarDespesaComponent implements OnInit {

  totalValidar = false;
  validarData = false;
  validarStatus = false;
  validarDescricao = false;
  validarCliente = false;
  validarCategoria = false;
  validarValor = false;

  statusValue: any[];
  fornecedorValue: any[];
  categoriaValue: any[];
  valueUid: string;
  action = 'Ok';

  despesaForm = {
    data: null,
    status: null,
    descricao: null,
    cliente: null,
    categoria: null,
    valor: null,
    despesa_receita: 'despesa'
  };

  carregando = true;

  constructor(
    private dadosFixosService: DadosFixosService,
    private afs: AngularFirestore,
    private storageService: StorageService,
    private snackBar: MatSnackBar,
    private authService: AuthService
  ) {
    this.valueUid = this.storageService.obterUid();
  }

  ngOnInit() {
    this.buscarOptions();
  }

  buscarOptions() {
    this.dadosFixosService.getStatusDespesa().subscribe((resp) => {
      this.statusValue = resp;
    }, error => {
      console.log(error)
    });

    this.afs.collection('users').doc(this.valueUid).collection('listaCategorias').snapshotChanges().subscribe(resp => {
      this.categoriaValue = resp.map(item => {
        return {
          id: item.payload.doc.id,
          ...item.payload.doc.data()
        }
      })
      this.carregando = false
    }, error => {
      this.authService.errorFirebase(error);
      console.log(error)
    });

    this.afs.collection('users').doc(this.valueUid).collection('listaFornecedores').snapshotChanges().subscribe(resp => {
      this.fornecedorValue = resp.map(item => {
        return {
          id: item.payload.doc.id,
          ...item.payload.doc.data()
        }
      })
    }, error => {
      this.authService.errorFirebase(error);
      console.log(error)
    });
  }

  validacao() {

    if (this.despesaForm.data == null) {
      this.validarData = true;
      this.totalValidar = true;
    }

    if (this.despesaForm.status == null) {
      this.validarStatus = true;
      this.totalValidar = true;
    }

    if (this.despesaForm.descricao == null) {
      this.validarDescricao = true;
      this.totalValidar = true;
    }

    if (this.despesaForm.cliente == null) {
      this.validarCliente = true;
      this.totalValidar = true;
    }

    if (this.despesaForm.categoria == null) {
      this.validarCategoria = true;
      this.totalValidar = true;
    }

    if (this.despesaForm.valor == null) {
      this.validarValor = true;
      this.totalValidar = true;
    }
    
  }

  async gravarDespesa() {
    this.validacao();

    if (this.totalValidar == true) {
      return
    }

    const current = new Date();
    const timestamp = String(current.getTime());

    try {
      this.afs.collection('users').doc(this.valueUid).collection('fluxo-caixa').doc(timestamp).set({
        data: this.despesaForm.data.getTime(),
        status: this.despesaForm.status,
        descricao: this.despesaForm.descricao,
        cliente: this.despesaForm.cliente,
        categoria: this.despesaForm.categoria,
        valor: this.despesaForm.valor,
        despesa_receita: this.despesaForm.despesa_receita,
        created_at: new Date()
      })

      this.limparCampo();

      var message = 'Registro salvo com sucesso!';
      this.snackBar.open(message, this.action, {
        duration: 5000,
        panelClass: 'my-snack-bar'
      });

    } catch (error) {
      this.authService.errorFirebase(error);
      console.log(error);

      var message = 'Erro ao salvar. Favor tente novamente!';
      this.snackBar.open(message, this.action, {
        duration: 5000,
        panelClass: 'my-snack-bar'
      });

    }

  }

  limparCampo() {
    this.despesaForm.data = null;
    this.despesaForm.status = null;
    this.despesaForm.descricao = null;
    this.despesaForm.cliente = null;
    this.despesaForm.categoria = null;
    this.despesaForm.valor = null;
  }


}

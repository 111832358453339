import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ambiente-seguro',
  templateUrl: './ambiente-seguro.component.html',
  styleUrls: ['./ambiente-seguro.component.scss']
})
export class AmbienteSeguroComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { AngularFireAuth } from '@angular/fire/auth';
import { StorageService } from './storage.service';

@Injectable()
export class AuthService {

  action = 'Ok';

  constructor(
    public afAuth: AngularFireAuth,
    private snackBar: MatSnackBar,
    private storageService: StorageService
  ) { }

  async logout() {
    try {
      await this.afAuth.auth.signOut();
      this.storageService.limparRegistros();
    }
    catch (error) {
      console.log(error)
      var message = 'Erro ao realizar o logout. Favor tente novamente!';

      this.snackBar.open(message, this.action, {
        duration: 5000,
        panelClass: 'my-snack-bar'
      });
    }
  }

  getAuth() {
    return this.afAuth.auth;
  }

  errorFirebase(error){
    if (error = "FirebaseError: Missing or insufficient permissions. ") {
      this.logout();
    }
  }

}

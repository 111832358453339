import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { EsqueceuSenhaComponent } from '../modal/esqueceu-senha/esqueceu-senha.component';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { auth } from 'firebase';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { StorageService } from 'src/app/_service/storage.service';
import { NavigateService } from 'src/app/_service/navigate.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  email: any;
  password: any;
  action = 'Ok';

  constructor(
    public afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private storageProvider: StorageService,
    public dialog: MatDialog,
    private router: Router,
    private snackBar: MatSnackBar,
    private ref: ChangeDetectorRef,
    private navigateService: NavigateService
    ) { }
    
  ngOnInit() {
    this.ref.detectChanges()
  }

  irNovoUsuario(){
    this.navigateService.navigate('novo-usuario');
  }

  esqueceuSenha() {
    const dialogRef = this.dialog.open(EsqueceuSenhaComponent);

    dialogRef.afterClosed().subscribe(result => {

      if(result == '') return;

      this.afAuth.auth.sendPasswordResetEmail(result)
        .then(resp => {
          Swal.fire({
            confirmButtonColor: '#F1B62B',
            title: 'Enviado!',
            text: 'Um link de redefinição de senha foi enviado para o seu endereço de e-mail: ' + result
          })
        })
        .catch(error => {

          console.log(error)
          var message = 'Erro ao enviar o link de redefinição de senha. Favor tente novamente!';

          if (error.message == 'There is no user record corresponding to this identifier. The user may have been deleted.') {
            message = 'Não há registro de usuário correspondente a este endereço de e-mail!';
          } 

          this.snackBar.open(message, this.action, {
            duration: 5000,
            panelClass: 'my-snack-bar'
          });

        });

    });
  }

  async loginEmailSenha() {
    try {
      const result = await this.afAuth.auth.signInWithEmailAndPassword(this.email, this.password);
      this.storageProvider.salvarUid(result.user.uid);
      this.storageProvider.salvarBloqueio(false);
      if (result.user) { this.router.navigate(['fluxo-caixa']); }
    }
    catch (error) {
      console.log(error)
      var message = 'Erro ao realizar o login. Favor tente novamente!';

      if (error.message == 'The password is invalid or the user does not have a password.') {
        message = 'A senha é inválida. Favor tente novamente!';

      } else if (error.message == 'There is no user record corresponding to this identifier. The user may have been deleted.') {
        message = 'E-mail é inválida. Favor tente novamente!';

      }

      this.snackBar.open(message, this.action, {
        duration: 5000,
        panelClass: 'my-snack-bar'
      });

    }
  }

  async loginFacebook() {
    const provider = new auth.FacebookAuthProvider();
    const credential = await this.afAuth.auth.signInWithPopup(provider);

    this.salvarDados(credential);

  }

  async loginGoogle() {
    const provider = new auth.GoogleAuthProvider();
    const credential = await this.afAuth.auth.signInWithPopup(provider);

    this.salvarDados(credential);

  }

  salvarDados(credential) {
    this.storageProvider.salvarUid(credential.user.uid);
    this.storageProvider.salvarBloqueio(false);
    
    const dataVenc = new Date();
    dataVenc.setDate(dataVenc.getDate() + 7);
    const dataVencimento = dataVenc.getTime();

    if (credential.additionalUserInfo.isNewUser) {
      this.afs.collection("users").doc(credential.user.uid).collection("geralUsuario").doc("usuario").set({
        usuario: {
          uid: credential.user.uid,
          email: credential.user.email,
          displayName: credential.user.displayName,
          photoURL: credential.user.photoURL,
          created_at: new Date(),
          aceite_termos_privacidade: true,
          expira_assinatura: dataVencimento
        }, empresa: {
          cnpj: null,
          nome: null,
          telefone: null,
          email: null
        }
      });

      const current = new Date();
      const dataAtual = current.getTime();

      this.afs.collection("users").doc(credential.user.uid).collection("pagamento").doc(String(dataAtual)).set({
        data: current.getTime(),
        plano: 'Gratuito',
        forma_pagamento: '',
        valor: 0,
        nota_fiscal: '',
        transaction_id: null
      });

      var itensCategoria = ['Projeto', 'Produto', 'Gastos fixos', 'Impostos', 'Serviços', 'Salários/Pró Labores']

      itensCategoria.forEach(item => {
        this.afs.collection("users").doc(credential.user.uid).collection("listaCategorias").add({ nome: item, created_at: new Date() });
      });

      if (credential.user) this.router.navigate(['fluxo-caixa']);

    } else {
      if (credential.user) this.router.navigate(['fluxo-caixa']);
    }

  }

}

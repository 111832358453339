import { Component, OnInit } from '@angular/core';
import { WordpressService } from 'src/app/_service/wordpress.service';

@Component({
  selector: 'app-politica-privacidade',
  templateUrl: './politica-privacidade.component.html',
  styleUrls: ['./politica-privacidade.component.scss']
})
export class PoliticaPrivacidadeComponent implements OnInit {

  titulo: string;
  corpo: string;
  carregando = true;

  constructor(
    private wordpressService: WordpressService
  ) { }

  ngOnInit() {
    this.getText();
  }

  getText() {
    const id = 5
    this.wordpressService.obter_uma_page(id).subscribe(resp => {
      this.titulo = resp.title.rendered;
      this.corpo = resp.content.rendered;  
      this.carregando = false;
    }, error => {
      console.log(error)
    })
  }

}

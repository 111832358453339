import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { config } from 'src/config';

@Injectable()
export class WordpressService {

  constructor(
    public http: HttpClient
  ) { }

  obterTodosPosts(page, per_page): Observable<any> {
    return this.http.get(config.obterUrlAPI() + 'posts?page=' + page + '&per_page=' + per_page );
  }

  obterTodosPostsMesmo(): Observable<any> {
    return this.http.get(config.obterUrlAPI() + 'posts' );
  }

  obter_uma_page(id): Observable<any> {
    return this.http.get(config.obterUrlAPI() + 'pages/' + id);
  }

  obter_um_post(id): Observable<any> {
    return this.http.get(config.obterUrlAPI() + 'posts/' + id);
  }

  obter_imagem_destaque(id): Observable<any> {
    return this.http.get(config.obterUrlAPI() + 'media/' + id);
  }

}

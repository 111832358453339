import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class ClientesFornecedoresService {

  constructor(
    private http: HttpClient,
  ) { 

    

  }

  getClientes(): Observable<any> {
    //   return this.http.get(config.obterUrlAPIPortalAluno() + 'anos?orderBy=nome&sortedBy=asc');

    return Observable.create((x) => {
      return x.next([
        {
          value: 1,
          name: 'Cliente 1',
          telefone: '+55 47 98843109',
          nome_titular: 'Titular do cliente 1',
          cnpj_cpf: '342.362.060-99',
          banco: { value: 341, name: '341 - Banco 1' },
          agencia: '8233',
          conta: '26924-3'
        }, {
          value: 2,
          name: 'Cliente 2',
          telefone: '+55 47 98843109',
          nome_titular: 'Titular do cliente 2',
          cnpj_cpf: '342.362.060-99',
          banco: { value: 1, name: '001 - Banco 2' },
          agencia: '8233',
          conta: '26924-3'
        }, {
          value: 3,
          name: 'Cliente 3',
          telefone: '+55 47 98843109',
          nome_titular: 'Titular do cliente 3',
          cnpj_cpf: '342.362.060-99',
          banco: { value: 341, name: '341 - Banco 1' },
          agencia: '8233',
          conta: '26924-3'
        },
      ])
    });
  }

  getFornecedores(): Observable<any> {
    //   return this.http.get(config.obterUrlAPIPortalAluno() + 'anos?orderBy=nome&sortedBy=asc');

    return Observable.create((x) => {
      return x.next([
        {
          value: 1,
          name: 'Fornecedor 1',
          telefone: '+55 47 98843109',
          nome_titular: 'Titular do fornecedor 1',
          cnpj_cpf: '342.362.060-99',
          banco: { value: 341, name: '341 - Banco 1' },
          agencia: '8233',
          conta: '26924-3'
        }, {
          value: 2,
          name: 'Fornecedor 2',
          telefone: '+55 47 98843109',
          nome_titular: 'Titular do fornecedor 2',
          cnpj_cpf: '342.362.060-99',
          banco: { value: 341, name: '341 - Banco 1' },
          agencia: '8233',
          conta: '26924-3'
        }, {
          value: 3,
          name: 'Fornecedor 3',
          nome_titular: 'Titular do fornecedor 3',
          telefone: '+55 47 98843109',
          cnpj_cpf: '342.362.060-99',
          banco: { value: 341, name: '341 - Banco 1' },
          agencia: '8233',
          conta: '26924-3'
        },
      ])
    });
  }

  getCategorias(): Observable<any> {
    //   return this.http.get(config.obterUrlAPIPortalAluno() + 'anos?orderBy=nome&sortedBy=asc');

    return Observable.create((x) => {
      return x.next([
        {
          value: 1,
          name: 'Categoria 1',
        }, {
          value: 2,
          name: 'Categoria 2',
        }, {
          value: 3,
          name: 'Categoria 3',
        },
      ])
    });
  }


}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contato',
  templateUrl: './contato.component.html',
  styleUrls: ['./contato.component.scss']
})
export class ContatoComponent implements OnInit {

  nome: string;
  email: string;
  telefone: string;
  mensagem: string;




  constructor() { }

  ngOnInit() {
  }

  enviarFormulario(){
    
    //codigo
  }

}

import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatSnackBar } from '@angular/material';
import { StorageService } from 'src/app/_service/storage.service';
import { NavigateService } from 'src/app/_service/navigate.service';

@Component({
  selector: 'app-novo-usuario',
  templateUrl: './novo-usuario.component.html',
  styleUrls: ['./novo-usuario.component.scss']
})
export class NovoUsuarioComponent implements OnInit {

  userRegister = {
    email: null,
    password: null,
    nome: null,
    confirm_password: null
  };

  action = 'Ok';

  constructor(
    public afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router,
    private storageProvider: StorageService,
    private snackBar: MatSnackBar,
    private ref: ChangeDetectorRef,
    private navigateService: NavigateService
  ) { }

  ngOnInit() {
    this.ref.detectChanges()
  }

  irLogin() {
    this.navigateService.navigate('/login');
  }


  async register() {

    const dataVenc = new Date();
    dataVenc.setDate(dataVenc.getDate() + 7);
    const dataVencimento = dataVenc.getTime();


    if (this.userRegister.password != this.userRegister.confirm_password) {
      var message = 'Senha Nova e Confirmar senha não coincidem!';

      this.snackBar.open(message, 'Ok', {
        duration: 5000,
        panelClass: 'my-snack-bar'
      });

      return;
    }

    try {
      const result = await this.afAuth.auth.createUserWithEmailAndPassword(this.userRegister.email, this.userRegister.password);

      this.storageProvider.salvarUid(result.user.uid);
      this.storageProvider.salvarBloqueio(false);
      
      this.afs.collection("users").doc(result.user.uid).collection("geralUsuario").doc("usuario").set({
        usuario: {
          uid: result.user.uid,
          email: result.user.email,
          displayName: this.userRegister.nome,
          created_at: new Date(),
          aceite_termos_privacidade: true,
          expira_assinatura: dataVencimento
        }, empresa: {
          cnpj: null,
          nome: null,
          telefone: null,
          email: null
        }
      });

      const current = new Date();
      const dataAtual = current.getTime();

      this.afs.collection("users").doc(result.user.uid).collection("pagamento").doc(String(dataAtual)).set({
        data: current.getTime(),
        plano: 'Gratuito',
        forma_pagamento: '',
        valor: 0,
        nota_fiscal: '',
        transaction_id: null
      });

      var itensCategoria = ['Projeto', 'Produto', 'Gastos fixos', 'Impostos', 'Serviços', 'Salários/Pró Labores']

      itensCategoria.forEach(item => {
        this.afs.collection("users").doc(result.user.uid).collection("listaCategorias").add({ nome: item, created_at: new Date() });
      });

      if (result.user) this.router.navigate(['fluxo-caixa']);
    }
    catch (error) {
      console.log(error)
      var message = 'Erro ao realizar o login. Favor tente novamente!';

      if (error.message == 'Password should be at least 6 characters') {
        message = 'A senha deve ter pelo menos 6 caracteres';

      } else if (error.message == 'The email address is badly formatted.') {
        message = 'O endereço de e-mail está formatado incorretamente.';

      } else if (error.message == 'The email address is already in use by another account.') {
        message = 'O endereço de e-mail já está sendo usado por outra conta.';
      }

      this.snackBar.open(message, this.action, {
        duration: 5000,
        panelClass: 'my-snack-bar'
      });
    }

  }
}
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { AdicionarCategoriaComponent } from '../modal/adicionar-categoria/adicionar-categoria.component';
import Swal from 'sweetalert2';
import { AngularFirestore } from '@angular/fire/firestore';
import { StorageService } from 'src/app/_service/storage.service';
import { AuthService } from 'src/app/_service/auth.service';


@Component({
  selector: 'app-categoria',
  templateUrl: './categoria.component.html',
  styleUrls: ['./categoria.component.scss']
})
export class CategoriaComponent implements OnInit {

  categorias: any;
  valueUid: string;
  action = 'Ok';
  carregando = true;

  constructor(
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private afs: AngularFirestore,
    private storageService: StorageService,
    private authService: AuthService
  ) {
    this.valueUid = this.storageService.obterUid();
  }

  ngOnInit() {
    this.getCategorias();
  }

  adicionarCategoria() {
    const dialogRef = this.dialog.open(AdicionarCategoriaComponent, {
      panelClass: 'modalClassAdicionar',
      data: {
        categoria: null,
        opcao: 'add'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.afs.collection('users').doc(this.valueUid).collection('listaCategorias').add({
        nome: result.nome,
        created_at: new Date()
      })
    });
  }

  getCategorias() {
    this.afs.collection('users').doc(this.valueUid).collection('listaCategorias').snapshotChanges().subscribe(resp => {
      this.categorias = resp.map(item => {
        return {
          id: item.payload.doc.id,
          ...item.payload.doc.data()
        }
      });

      this.carregando = false
    }, error => {
      this.authService.errorFirebase(error);
      console.log(error);

      var message = 'Não foi possível carregar as categorias. Favor, atualize a página!';

      this._snackBar.open(message, this.action, {
        duration: 2000,
        panelClass: 'my-snack-bar'
      });
    })
  }

  editarCategoria(categoria) {
    const dialogRef = this.dialog.open(AdicionarCategoriaComponent, {
      panelClass: 'modalClassAdicionar',
      data: {
        categoria: categoria,
        opcao: 'editar'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.afs.collection('users').doc(this.valueUid).collection('listaCategorias').doc(categoria.id).update({
        nome: result.nome,
        updated_at: new Date()
      });
    }, error => {
      this.authService.errorFirebase(error);
      console.log(error)
    });
  }

  excluirCategoria(categoria) {
    Swal.fire({
      title: 'Realmente deseja excluir?',
      text: "Após a confirmação, essa ação não poderá ser desfeita!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#F1B62B',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, tenho certeza!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        // codigo da ação aqui



        var message = 'Item excluído com sucesso!';
        var action = 'Ok';

        this._snackBar.open(message, action, {
          duration: 2000,
          panelClass: 'my-snack-bar'
        });

      }
    })
  }
}

import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AngularFirestore } from '@angular/fire/firestore';
import { StorageService } from 'src/app/_service/storage.service';
import { AuthService } from 'src/app/_service/auth.service';

@Component({
  selector: 'app-add-filtro',
  templateUrl: './add-filtro.component.html',
  styleUrls: ['./add-filtro.component.scss']
})
export class AddFiltroComponent implements OnInit {

  categorias: any;
  clientes: any;
  fornecedores: any;
  valueUid: string;
  colorMatCheckbox = '#F1B62B';

  constructor(
    private afs: AngularFirestore,
    private storageService: StorageService,
    private authService: AuthService,
    public thisDialogRef: MatDialogRef<AddFiltroComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.valueUid = this.storageService.obterUid();
  }

  ngOnInit() {
    this.buscarCategoria();
    this.buscarClientes();
    this.buscarFornecedores();
  }

  onCloseEnviar() {
    const data = {
      categorias: this.categorias,
      clientes: this.clientes,
      fornecedores: this.fornecedores
    }

    this.thisDialogRef.close(data);
  }

  mudarChecked(checked: boolean, i: number, campo: string) {
    if (campo == 'categoria') {
      this.categorias[i].checked = checked;

    } else if (campo == 'cliente') {
      this.clientes[i].checked = checked;

    } else if (campo == 'fornecedor') {
      this.fornecedores[i].checked = checked;
    }
  }

  limparFiltro() {
    this.categorias.forEach(dadosResult => {
      if (dadosResult.checked) {
        dadosResult.checked = false;
      }
    });

    this.clientes.forEach(dadosResult => {
      if (dadosResult.checked) {
        dadosResult.checked = false;
      }
    });

    this.fornecedores.forEach(dadosResult => {
      if (dadosResult.checked) {
        dadosResult.checked = false;
      }
    });
  }

  buscarCategoria() {
    this.afs.collection('users').doc(this.valueUid).collection('listaCategorias').snapshotChanges().subscribe(resp => {
      this.categorias = resp.map(item => {
        return {
          id: item.payload.doc.id,
          checked: false,
          ...item.payload.doc.data()
        }
      });

    }, error => {
      this.authService.errorFirebase(error);
      console.log(error)
    })
  }

  buscarClientes() {
    this.afs.collection('users').doc(this.valueUid).collection('listaClientes').snapshotChanges().subscribe(resp => {
      this.clientes = resp.map(item => {
        return {
          id: item.payload.doc.id,
          checked: false,
          ...item.payload.doc.data()
        }
      });

    }, error => {
      this.authService.errorFirebase(error);
      console.log(error)
    })
  }

  buscarFornecedores() {
    this.afs.collection('users').doc(this.valueUid).collection('listaFornecedores').snapshotChanges().subscribe(resp => {
      this.fornecedores = resp.map(item => {
        return {
          id: item.payload.doc.id,
          checked: false,
          ...item.payload.doc.data()
        }
      });

    }, error => {
      this.authService.errorFirebase(error);
      console.log(error)
    })
  }

}
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatNativeDateModule } from '@angular/material';
import { AngularMaterialModule } from 'src/app/angular-material-module';
import { SpinnerComponent } from './spinner.component';

@NgModule({
  declarations: [
    SpinnerComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    AngularMaterialModule,
    MatNativeDateModule,    
  ],
  exports: [SpinnerComponent]
})
export class SpinnerModule {
}
import { Component, OnInit } from '@angular/core';
import { WordpressService } from 'src/app/_service/wordpress.service';
import { config } from 'src/config';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ultimos-post',
  templateUrl: './ultimos-post.component.html',
  styleUrls: ['./ultimos-post.component.scss']
})
export class UltimosPostComponent implements OnInit {

  litagemPost: any[];

  constructor(
    private wordpressService: WordpressService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.getPosts();
  }

  getPosts() {
    const page = 1;
    const per_page = 3;

    this.wordpressService.obterTodosPosts(page, per_page).subscribe(resp => {    

      this.litagemPost = resp.map(x => {
        return this.popularPost(x);
      });

      this.obterImagem();

    }, error => {
      console.log(error)
    })
  }

  popularPost(x) {
    return {
      id: x.id,
      titulo: x.title.rendered,
      idImagemDestaque: x.featured_media,
      imagemDestaque: '',
      iDCategories: x.categories,
      categories: '',
    };
  }

  obterImagem() {
    this.litagemPost.forEach((i) => {
      this.wordpressService.obter_imagem_destaque(i.idImagemDestaque).subscribe((resp) => {
        var img = resp.media_details.file;
        i.imagemDestaque = config.caminhoUpload() + img;
        i.alt_text = resp.alt_text;
      });
    });
  }

  irPost(id, slug) {
    this.router.navigate(['/blog/post/'], {
      queryParams: {
        id: id,
        post: slug
      }
    });
  }

}

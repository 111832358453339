// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  wp_link : 'https://girandoaroda.com.br',

  firebaseConfig: {
    apiKey: "AIzaSyDj8yidT99X1M_VKaFyNlJIR-GLVwr0Svs",
    authDomain: "girando-a-roda.firebaseapp.com",
    databaseURL: "https://girando-a-roda.firebaseio.com",
    projectId: "girando-a-roda",
    storageBucket: "girando-a-roda.appspot.com",
    messagingSenderId: "671052693332",
    appId: "1:671052693332:web:8d73528e4ef85e338992c1",
    measurementId: "G-7LVCY1HQXS"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DadosFixosService } from 'src/app/_service/dados_fixos.service';
import { ClientesFornecedoresService } from 'src/app/_service/clientes_fornecedores.service';
import { FormControl } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/firestore';
import { StorageService } from 'src/app/_service/storage.service';
import { AuthService } from 'src/app/_service/auth.service';

@Component({
  selector: 'app-editar-receita-despesa',
  templateUrl: './editar-receita-despesa.component.html',
  styleUrls: ['./editar-receita-despesa.component.scss']
})
export class EditarReceitaDespesaComponent implements OnInit {

  statusValue: any[];
  clienteValue: any[];
  categoriaValue: any[];

  dados = {
    data: new FormControl(new Date()),
    categoria: {},
    descricao: null,
    cliente: {},
    valor: null,
    despesa_receita: null,
    status: {
      value: null,
      name: null,     
    },
  }

  dateTeste: any;
  valueUid: string;
  constructor(
    private dadosFixosService: DadosFixosService,
    private afs: AngularFirestore,
    private storageService: StorageService,
    private authService: AuthService,
    public thisDialogRef: MatDialogRef<EditarReceitaDespesaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.valueUid = this.storageService.obterUid();
  }

  ngOnInit() {
    this.dados = {
      data: this.data.dados.data,
      categoria: this.data.dados.categoria,
      descricao: this.data.dados.descricao,
      cliente: this.data.dados.cliente,
      valor: this.data.dados.valor,
      despesa_receita: this.data.dados.despesa_receita,
      status: this.data.dados.status,
    }

    this.buscarOptions();
  }

  salvar() {
    this.thisDialogRef.close(this.dados);
  }

  buscarOptions() {
    this.afs.collection('users').doc(this.valueUid).collection('listaCategorias').snapshotChanges().subscribe(resp => {
      this.categoriaValue = resp.map(item => {
        return {
          id: item.payload.doc.id,
          ...item.payload.doc.data()
        }
      })

    }, error => {
      this.authService.errorFirebase(error);
      console.log(error)
    });


    if (this.data.tipo == 'receita') {

      this.afs.collection('users').doc(this.valueUid).collection('listaClientes').snapshotChanges().subscribe(resp => {
        this.clienteValue = resp.map(item => {
          return {
            id: item.payload.doc.id,
            ...item.payload.doc.data()
          }
        })

      }, error => {
        this.authService.errorFirebase(error);
        console.log(error)
      });

      this.dadosFixosService.getStatusReceita().subscribe((resp) => {
        this.statusValue = resp;
      }, error => {
        console.log(error)
      });

    } else {
      
      this.afs.collection('users').doc(this.valueUid).collection('listaFornecedores').snapshotChanges().subscribe(resp => {
        this.clienteValue = resp.map(item => {
          return {
            id: item.payload.doc.id,
            ...item.payload.doc.data()
          }
        })

      }, error => {
        this.authService.errorFirebase(error);
        console.log(error)
      });

      this.dadosFixosService.getStatusDespesa().subscribe((resp) => {
        this.statusValue = resp;

      }, error => {
        console.log(error)
      });
    }
  }

  mudarStatus(status){
    this.dados.status = status;
  }

  mudarCliente(cliente){
    this.dados.cliente = cliente
  }

  mudarCategoria(categoria){
    this.dados.categoria = categoria
  }

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { config } from 'src/config';

@Injectable({
    providedIn: 'root'
})
export class PagarmeService {

    api_key = 'ak_test_ALQdnPo0O8tEbP1rxaQnlF9LXkNgMr';

    constructor(
        public http: HttpClient
    ) { }

    transacao(data): Observable<any> {
        return this.http.post('https://api.pagar.me/1/transactions?api_key=' + this.api_key, data);
    }

    verificarTransacao(idTransacao): Observable<any> {
        return this.http.get('https://api.pagar.me/1/transactions/' + idTransacao + '?api_key=' + this.api_key);
    }
}

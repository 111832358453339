import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material';

import { AngularMaterialModule } from './angular-material-module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './sistema_web/login/login.component';
import { FluxoCaixaComponent } from './sistema_web/fluxo-caixa/fluxo-caixa.component';
import { PerfilUsuarioComponent } from './sistema_web/perfil-usuario/perfil-usuario.component';
import { RegistrarDespesaComponent } from './sistema_web/registrar-despesa/registrar-despesa.component';
import { RegistrarReceitaComponent } from './sistema_web/registrar-receita/registrar-receita.component';
import { NavbarComponent } from './sistema_web/navbar/navbar.component';
import { TemplatePadraoComponent } from './template-padrao/template-padrao.component';
import { EsqueceuSenhaComponent } from './sistema_web/modal/esqueceu-senha/esqueceu-senha.component';
import { ClientesComponent } from './sistema_web/clientes/clientes.component';
import { FornecedoresComponent } from './sistema_web/fornecedores/fornecedores.component';
import { AddFiltroComponent } from './sistema_web/modal/add-filtro/add-filtro.component';
import { AtualizacaoStatusComponent } from './sistema_web/modal/atualizacao-status/atualizacao-status.component';
import { DadosPessoaisComponent } from './sistema_web/perfil-usuario/dados-pessoais/dados-pessoais.component';
import { PlanoAssinaturaComponent } from './sistema_web/perfil-usuario/plano-assinatura/plano-assinatura.component';
import { AlterarSenhaComponent } from './sistema_web/modal/alterar-senha/alterar-senha.component';
import { AdicionarClienteFornecedorComponent } from './sistema_web/modal/adicionar-cliente-fornecedor/adicionar-cliente-fornecedor.component';
import { CategoriaComponent } from './sistema_web/categoria/categoria.component';
import { AdicionarCategoriaComponent } from './sistema_web/modal/adicionar-categoria/adicionar-categoria.component';
import { EditarClienteFornecedorComponent } from './sistema_web/modal/editar-cliente-fornecedor/editar-cliente-fornecedor.component';
import { EditarReceitaDespesaComponent } from './sistema_web/modal/editar-receita-despesa/editar-receita-despesa.component';
import { NovoUsuarioComponent } from './sistema_web/novo-usuario/novo-usuario.component';
import { RedefinirSenhaComponent } from './sistema_web/redefinir-senha/redefinir-senha.component';
import { Page404Component } from './page404/page404.component';
import { IndexComponent } from './site/index/index.component';
import { PoliticaPrivacidadeComponent } from './site/politica-privacidade/politica-privacidade.component';
import { TermosUsoComponent } from './site/termos-uso/termos-uso.component';
import { ContatoComponent } from './site/contato/contato.component';
import { SuporteComponent } from './site/suporte/suporte.component';
import { TemplateSiteComponent } from './template-site/template-site.component';
import { BlogModule } from './site/blog/blog.module';
import { PrecosComponent } from './site/precos/precos.component';
import { MenuSiteModule } from './site/menu-site/menu-site.module';
import { RodapeSiteModule } from './site/rodape-site/rodape-site.module';


// Firebase
import { AngularFireModule } from '@angular/fire';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireDatabaseModule } from '@angular/fire/database';

import { environment } from 'src/environments/environment';
import { AuthService } from './_service/auth.service';

import { PasswordDirective } from './_directive/password.directive';
import { NavigateService } from './_service/navigate.service';
import { WordpressService } from './_service/wordpress.service';

// Multi idioma
// https://www.codeandweb.com/babeledit/tutorials/how-to-translate-your-angular8-app-with-ngx-translate
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';


import { NgxMaskModule } from 'ngx-mask';
import { SpinnerModule } from './spinner/spinner.module';
import { FormaPagamentoComponent } from './forma-pagamento/forma-pagamento.component';
import { AmbienteSeguroComponent } from './ambiente-seguro/ambiente-seguro.component';
import { RenovarAssinaturaComponent } from './sistema_web/renovar-assinatura/renovar-assinatura.component';
import { TabelaPrecoComponent } from './tabela-preco/tabela-preco.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    FluxoCaixaComponent,
    PerfilUsuarioComponent,
    RegistrarDespesaComponent,
    RegistrarReceitaComponent,
    NavbarComponent,
    TemplatePadraoComponent,
    EsqueceuSenhaComponent,
    ClientesComponent,
    FornecedoresComponent,
    AddFiltroComponent,
    AtualizacaoStatusComponent,
    DadosPessoaisComponent,
    PlanoAssinaturaComponent,
    AlterarSenhaComponent,
    AdicionarClienteFornecedorComponent,
    CategoriaComponent,
    AdicionarCategoriaComponent,
    EditarClienteFornecedorComponent,
    EditarReceitaDespesaComponent,
    Page404Component,
    IndexComponent,
    NovoUsuarioComponent,
    RedefinirSenhaComponent,
    PasswordDirective,
    PoliticaPrivacidadeComponent,
    TermosUsoComponent,
    ContatoComponent,
    SuporteComponent,
    TemplateSiteComponent,
    PrecosComponent,
    FormaPagamentoComponent,
    AmbienteSeguroComponent,
    RenovarAssinaturaComponent,
    TabelaPrecoComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AngularMaterialModule,
    MatNativeDateModule,
    FormsModule,
    ReactiveFormsModule,

    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAnalyticsModule,
    AngularFirestoreModule,
    AngularFireAuthModule,

    AngularFireDatabaseModule,
    BlogModule,
    MenuSiteModule,
    RodapeSiteModule,
    SpinnerModule,
   
    NgxMaskModule.forRoot(),

    // ngx-translate and the loader module
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })

  ],
  entryComponents: [
    EsqueceuSenhaComponent,
    AddFiltroComponent,
    AtualizacaoStatusComponent,
    AlterarSenhaComponent,
    AdicionarClienteFornecedorComponent,
    AdicionarCategoriaComponent,
    EditarClienteFornecedorComponent,
    EditarReceitaDespesaComponent,
    FormaPagamentoComponent,
    RenovarAssinaturaComponent
  ],
  providers: [
    AuthService,
    NavigateService,
    WordpressService,
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }


// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}


import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatSnackBar } from '@angular/material';
import { DadosFixosService } from 'src/app/_service/dados_fixos.service';
import { StorageService } from 'src/app/_service/storage.service';
import { AuthService } from 'src/app/_service/auth.service';

@Component({
  selector: 'app-registrar-receita',
  templateUrl: './registrar-receita.component.html',
  styleUrls: ['./registrar-receita.component.scss']
})
export class RegistrarReceitaComponent implements OnInit {

  totalValidar = false;
  validarData = false;
  validarStatus = false;
  validarDescricao = false;
  validarCliente = false;
  validarCategoria = false;
  validarValor = false;

  statusValue: any[];
  clienteValue: any[];
  categoriaValue: any[];
  valueUid: string;
  action = 'Ok';

  receitaForm = {
    data: null,
    status: null,
    descricao: null,
    cliente: null,
    categoria: null,
    valor: null,
    despesa_receita: 'receita'
  };

  carregando = true;

  constructor(
    private dadosFixosService: DadosFixosService,
    private afs: AngularFirestore,
    private storageService: StorageService,
    private snackBar: MatSnackBar,
    private authService: AuthService
  ) {
    this.valueUid = this.storageService.obterUid();
  }

  ngOnInit() {
    this.buscarOptions();
  }

  buscarOptions() {
    this.dadosFixosService.getStatusReceita().subscribe((resp) => {
      this.statusValue = resp;
    }, error => {
      console.log(error)
    });

    this.afs.collection('users').doc(this.valueUid).collection('listaCategorias').snapshotChanges().subscribe(resp => {
      this.categoriaValue = resp.map(item => {
        return {
          id: item.payload.doc.id,
          ...item.payload.doc.data()
        }
      })

      this.carregando = false
      
    }, error => {
      this.authService.errorFirebase(error);
      console.log(error)
    });

    this.afs.collection('users').doc(this.valueUid).collection('listaClientes').snapshotChanges().subscribe(resp => {
      this.clienteValue = resp.map(item => {
        return {
          id: item.payload.doc.id,
          ...item.payload.doc.data()
        }
      })
    }, error => {
      this.authService.errorFirebase(error);
      console.log(error)
    });

  }

  validacao() {

    if (this.receitaForm.data == null) {
      this.validarData = true;
      this.totalValidar = true;
    }

    if (this.receitaForm.status == null) {
      this.validarStatus = true;
      this.totalValidar = true;
    }

    if (this.receitaForm.descricao == null) {
      this.validarDescricao = true;
      this.totalValidar = true;
    }

    if (this.receitaForm.cliente == null) {
      this.validarCliente = true;
      this.totalValidar = true;
    }

    if (this.receitaForm.categoria == null) {
      this.validarCategoria = true;
      this.totalValidar = true;
    }

    if (this.receitaForm.valor == null) {
      this.validarValor = true;
      this.totalValidar = true;
    }

  }

  async gravarReceita() {
    this.validacao();

    if (this.totalValidar == true) {
      return
    }

    const current = new Date();
    const timestamp = String(current.getTime());
    
    try {
      this.afs.collection('users').doc(this.valueUid).collection('fluxo-caixa').doc(timestamp).set({
        data: this.receitaForm.data.getTime(),
        status: this.receitaForm.status,
        descricao: this.receitaForm.descricao,
        cliente: this.receitaForm.cliente,
        categoria: this.receitaForm.categoria,
        valor: this.receitaForm.valor,
        despesa_receita: this.receitaForm.despesa_receita,
        created_at: new Date()
      })

      this.limparCampo();

      var message = 'Registro salvo com sucesso!';
      this.snackBar.open(message, this.action, {
        duration: 5000,
        panelClass: 'my-snack-bar'
      });

    } catch (error) {
      this.authService.errorFirebase(error);
      console.log(error);

      var message = 'Erro ao salvar. Favor tente novamente!';
      this.snackBar.open(message, this.action, {
        duration: 5000,
        panelClass: 'my-snack-bar'
      });

    }

  }

  limparCampo() {
    this.receitaForm.data = null;
    this.receitaForm.status = null;
    this.receitaForm.descricao = null;
    this.receitaForm.cliente = null;
    this.receitaForm.categoria = null;
    this.receitaForm.valor = null;
  }

}

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material';
import Swal from 'sweetalert2';
import { AuthService } from 'src/app/_service/auth.service';

@Component({
  selector: 'app-redefinir-senha',
  templateUrl: './redefinir-senha.component.html',
  styleUrls: ['./redefinir-senha.component.scss']
})
export class RedefinirSenhaComponent implements OnInit {

  ngUnsubscribe: Subject<any> = new Subject<any>();
  actions: any;
  mode: string;
  actionCode: string;

  oldPassword: string;
  newPassword: string;
  confirmPassword: string;

  actionCodeChecked: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    public snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.route.queryParams
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(params => {

        if (!params) this.router.navigate(['/perfil-usuario']);

        this.mode = params.mode;
        this.actionCode = params.oobCode;

        switch (this.mode) {
          case 'resetPassword': {
            this.authService
              .getAuth()
              .verifyPasswordResetCode(this.actionCode)
              .then(email => {
                this.actionCodeChecked = true;
              }).catch(e => {

                console.log(e)
                var message = 'Algo de errado aconteceu. Favor, atualize a página!';

                this.snackBar.open(message, 'Ok', {
                  duration: 5000,
                  panelClass: 'my-snack-bar'
                });

              });
          } break
          case 'recoverEmail': {

          } break
          case 'verifyEmail': {

          } break
        }
      })
  }

  ngOnDestroy() {
    // End all subscriptions listening to ngUnsubscribe
    // to avoid memory leaks.
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  /**
   * Attempt to confirm the password reset with firebase and
   * navigate user back to home.
   */
  handleResetPassword() {
    if (this.newPassword != this.confirmPassword) {
      var message = 'Senha Nova e Confirmar senha não coincidem!';

      this.snackBar.open(message, 'Ok', {
        duration: 5000,
        panelClass: 'my-snack-bar'
      });

      return;
    }

    // Save the new password.
    this.authService.getAuth().confirmPasswordReset(
      this.actionCode,
      this.newPassword
    )
      .then(resp => {
        Swal.fire({
          confirmButtonColor: '#F1B62B',
          icon: 'success',
          title: 'Senha alterada!',
          text: 'Sua senha foi alterada com sucesso.'
        })

        this.router.navigate(['/login']);
      }).catch(e => {
        console.log(e)
        var message = 'Algo de errado aconteceu. Favor, atualize a página!';

        this.snackBar.open(message, 'Ok', {
          duration: 5000,
          panelClass: 'my-snack-bar'
        });
      });
  }

}

import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PostBlogComponent } from './post-blog/post-blog.component';
import { CategoriaBlogComponent } from './categoria-blog/categoria-blog.component';
import { ListagemBlogComponent } from './listagem-blog/listagem-blog.component';


const blogRoutes = [
  {
    path: '',
    pathMatch: 'full',
    component: ListagemBlogComponent
  }, {
    path: 'post',
    component: PostBlogComponent
  }, {
    path: 'categoria',
    component: CategoriaBlogComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(blogRoutes)],
  exports: [RouterModule]
})

export class BlogRoutingModule { }
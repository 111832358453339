import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { BlogComponent } from './blog.component';
import { CategoriaBlogComponent } from './categoria-blog/categoria-blog.component';
import { PostBlogComponent } from './post-blog/post-blog.component';
import { BlogRoutingModule } from './blog.routing.module';
import { ListagemBlogComponent } from './listagem-blog/listagem-blog.component';
import { MenuSiteModule } from '../menu-site/menu-site.module';
import { RodapeSiteModule } from '../rodape-site/rodape-site.module';
import { UltimosPostComponent } from './ultimos-post/ultimos-post.component';


// Multi idioma
// https://www.codeandweb.com/babeledit/tutorials/how-to-translate-your-angular8-app-with-ngx-translate
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { HttpLoaderFactory } from 'src/app/app.module';
import { SpinnerModule } from 'src/app/spinner/spinner.module';


@NgModule({
  declarations: [
    BlogComponent,
    CategoriaBlogComponent,
    PostBlogComponent,
    ListagemBlogComponent,
    UltimosPostComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    BlogRoutingModule,
    MenuSiteModule,
    RodapeSiteModule,
    SpinnerModule,

    // ngx-translate and the loader module
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [],
  bootstrap: [BlogComponent]
})
export class BlogModule {
}

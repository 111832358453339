import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StorageService } from '../_service/storage.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-forma-pagamento',
  templateUrl: './forma-pagamento.component.html',
  styleUrls: ['./forma-pagamento.component.scss']
})
export class FormaPagamentoComponent implements OnInit {

  aba_dados_pessoais = false;
  aba_endereco = false;
  aba_opcao_pagamento = true;
  aba_cartao = false;
  aba_boleto = false;
  opcao_escolhida: number; // 1 - boleto     2- cartao

  opcao_pagamento: string; //credit_card e boleto

  dados_pessoais = {
    name: null, //"Teste Agora",
    country: "br", //"br",
    email: null, //"mopheus@nabucodonozor.com",
    documents: {
      type: "cpf", //"cpf",
      number: null, //"30621143049"
    },
    phone_numbers: null, //"+5511999998888",
    birthday: null, //"1965-01-01"
  }

  endereco_cobranca = {
    address: {
      country: "br",
      state: null, //"sp",
      city: null, //"Cotia",
      neighborhood: null, //"Rio Cotia",
      street: null, //"Rua Matrix",
      street_number: null, //"9999",f
      zipcode: null, //"06714360"
    }
  }

  cartao = {
    card_number: null, //"Número do cartão inválido.",
    card_holder_name: null, //"Nome do portador inválido.",
    card_expiration: null, //"data de expiração inválido.",
    card_cvv: null, //"Código de segurança inválido."
  }

  valueUid: string;

  verificarTodos = false;
  verificarNomeCompleto = false;
  verificarEmail = false;
  verificarTelefone = false;
  verificarDataAniversario = false;
  verificarNumDoc = false;

  verificarEnderecoTodos = false;
  verificarEstado = false;
  verificarCidade = false;
  verificarBairro = false;
  verificarRua = false;
  verificarNumRua = false;
  verificarCEP = false;

  verificarCartaoTodos = false;
  verificarNumCartao = false;
  verificarNomeTitular = false;
  verificarVencimento = false;
  verificarCVV = false;

  constructor(
    private storageService: StorageService,
    public thisDialogRef: MatDialogRef<FormaPagamentoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.valueUid = this.storageService.obterUid();
  }

  ngOnInit() {

  }

  boleto() {
    this.opcao_escolhida = 1;
    this.aba_opcao_pagamento = false;
    this.aba_dados_pessoais = true;
  }

  cartaoCredito() {
    this.opcao_escolhida = 2;
    this.aba_opcao_pagamento = false;
    this.aba_dados_pessoais = true;
  }

  verificarDadosPessoais() {

    this.verificarTodos = false;
    this.verificarNomeCompleto = false;
    this.verificarEmail = false;
    this.verificarTelefone = false;
    this.verificarDataAniversario = false;
    this.verificarNumDoc = false;

    if (!this.dados_pessoais.name) {
      this.verificarTodos = true;
      this.verificarNomeCompleto = true;
    }

    if (!this.dados_pessoais.email) {
      this.verificarTodos = true;
      this.verificarEmail = true;
    }

    if (!this.dados_pessoais.documents.number) {
      this.verificarTodos = true;
      this.verificarNumDoc = true;
    }

    if (!this.dados_pessoais.phone_numbers) {
      this.verificarTodos = true;
      this.verificarTelefone = true;
    }

    if (!this.dados_pessoais.birthday) {
      this.verificarTodos = true;
      this.verificarDataAniversario = true;
    }

  }

  endereco() {
    this.verificarDadosPessoais();

    if (this.verificarTodos) {
      return

    } else {
      this.aba_dados_pessoais = false;
      this.aba_endereco = true;
    }
  }

  voltarDadosPessoais() {
    this.aba_endereco = false;
    this.aba_dados_pessoais = true;
  }

  voltarEndereco() {
    this.aba_cartao = false;
    this.aba_endereco = true;
  }

  verificarEnderecoCobranca() {

    this.verificarEnderecoTodos = false;
    this.verificarEstado = false;
    this.verificarCidade = false;
    this.verificarBairro = false;
    this.verificarRua = false;
    this.verificarNumRua = false;
    this.verificarCEP = false;

    if (!this.endereco_cobranca.address.state) {
      this.verificarEnderecoTodos = true;
      this.verificarEstado = true;
    }

    if (!this.endereco_cobranca.address.city) {
      this.verificarEnderecoTodos = true;
      this.verificarCidade = true;
    }

    if (!this.endereco_cobranca.address.neighborhood) {
      this.verificarEnderecoTodos = true;
      this.verificarBairro = true;
    }

    if (!this.endereco_cobranca.address.street) {
      this.verificarEnderecoTodos = true;
      this.verificarRua = true;
    }

    if (!this.endereco_cobranca.address.street_number) {
      this.verificarEnderecoTodos = true;
      this.verificarNumRua = true;
    }

    if (!this.endereco_cobranca.address.zipcode) {
      this.verificarEnderecoTodos = true;
      this.verificarCEP = true;
    }

  }

  pagamento() {
    this.verificarEnderecoCobranca();

    if (this.verificarEnderecoTodos) {
      return
    }

    if (this.opcao_escolhida == 1) {
      this.opcao_pagamento = "boleto";
      this.pagar();
    } else {
      this.opcao_pagamento = "credit_card";
      this.aba_endereco = false;
      this.aba_cartao = true;
    }
  }

  verificarDadosCartao() {

    this.verificarCartaoTodos = false;
    this.verificarNumCartao = false;
    this.verificarNomeTitular = false;
    this.verificarVencimento = false;
    this.verificarCVV = false;

    if (!this.cartao.card_number) {
      this.verificarCartaoTodos = true;
      this.verificarNumCartao = true;
    }

    if (!this.cartao.card_holder_name) {
      this.verificarCartaoTodos = true;
      this.verificarNomeTitular = true;
    }

    if (!this.cartao.card_expiration) {
      this.verificarCartaoTodos = true;
      this.verificarVencimento = true;
    }

    if (!this.cartao.card_cvv) {
      this.verificarCartaoTodos = true;
      this.verificarCVV = true;
    }

  }

  prePagamento() {
    this.verificarDadosCartao();

    if (this.verificarCartaoTodos) {
      console.log('passei pelo if')
      return
    } else {
      console.log('passei pelo else')
      this.pagar();
    }
  }

  pagar() {
    Swal.fire({
      confirmButtonColor: '#F1B62B',
      title: 'Carregando..',
      html: '<img style="width: 120px;" src="../../assets/spinner-gif.gif" alt="carregando...">'
    })

    const valorTotal = (this.data.valor * this.data.qtdd) * 100;
    var tipo_pessoa = "individual";
    const nome_cobranca = "Girando a roda";

    if (this.dados_pessoais.documents.type == 'cnpj') {
      tipo_pessoa = "corporation";
    }

    const data = {
      "amount": valorTotal,
      "card_number": this.cartao.card_number,
      "card_cvv": this.cartao.card_cvv,
      "card_expiration_date": this.cartao.card_expiration,
      "card_holder_name": this.cartao.card_holder_name,
      "customer": {
        "external_id": this.valueUid,
        "name": this.dados_pessoais.name,
        "type": tipo_pessoa,
        "country": this.dados_pessoais.country,
        "email": this.dados_pessoais.email,
        "documents": [
          {
            "type": this.dados_pessoais.documents.type,
            "number": this.dados_pessoais.documents.number
          }
        ],
        "phone_numbers": ["+" + this.dados_pessoais.phone_numbers],
        "birthday": this.dados_pessoais.birthday
      },
      "billing": {
        "name": nome_cobranca,
        "address": {
          "country": this.endereco_cobranca.address.country,
          "state": this.endereco_cobranca.address.state,
          "city": this.endereco_cobranca.address.city,
          "neighborhood": this.endereco_cobranca.address.neighborhood,
          "street": this.endereco_cobranca.address.street,
          "street_number": this.endereco_cobranca.address.street_number,
          "zipcode": this.endereco_cobranca.address.zipcode
        }
      }, "items": [{
        "id": "1",
        "title": this.data.qtdd + " mês de assinatura",
        "unit_price": valorTotal,
        "quantity": this.data.qtdd,
        "tangible": true
      }],
      "payment_method": this.opcao_pagamento//credit_card e boleto

    }

    this.thisDialogRef.close(data);
  }

}

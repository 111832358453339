import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ClientesFornecedoresService } from 'src/app/_service/clientes_fornecedores.service';
import { BancosService } from 'src/app/_service/bancos.service';

@Component({
  selector: 'app-editar-cliente-fornecedor',
  templateUrl: './editar-cliente-fornecedor.component.html',
  styleUrls: ['./editar-cliente-fornecedor.component.scss']
})
export class EditarClienteFornecedorComponent implements OnInit {


  bancos: any;
  clienteFornecedorForm = {
    nome: null,
    telefone: null,
    cnpj_cpf: null,
    nome_titular: null,
    banco: null,
    agencia: null,
    conta: null,
  }

  constructor(
    private clientesFornecedoresService: ClientesFornecedoresService,
    private bancosService: BancosService,
    public thisDialogRef: MatDialogRef<EditarClienteFornecedorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.getBanco();

    if (this.data.cliente != null) {
      this.clienteFornecedorForm = {
        nome: this.data.cliente.nome,
        telefone: this.data.cliente.telefone,
        cnpj_cpf: this.data.cliente.cnpj_cpf,
        nome_titular: this.data.cliente.nome_titular,
        banco: this.data.cliente.banco,
        agencia: this.data.cliente.agencia,
        conta: this.data.cliente.conta,
      }
    }
  }

  adicionarCliente() {
    this.thisDialogRef.close(this.clienteFornecedorForm);
  }

  getBanco() {
    this.bancosService.getBancos().subscribe(x => {
      this.bancos = x
    }, error => {
      console.log(error)
    });
  }

  // Mascara para CPF e CNPJ
  isCPF(): boolean {
    return this.clienteFornecedorForm.cnpj_cpf == null ? true : this.clienteFornecedorForm.cnpj_cpf.length < 12 ? true : false;
  }

  getCpfCnpjMask(): string {
    if (this.clienteFornecedorForm.cnpj_cpf != null) {
      return this.isCPF() ? '000.000.000-00' : '00.000.000/0000-00';
    }
  }

}

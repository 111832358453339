import { Component, OnInit } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { AdicionarClienteFornecedorComponent } from '../modal/adicionar-cliente-fornecedor/adicionar-cliente-fornecedor.component';
import Swal from 'sweetalert2';
import { EditarClienteFornecedorComponent } from '../modal/editar-cliente-fornecedor/editar-cliente-fornecedor.component';
import { AngularFirestore } from '@angular/fire/firestore';
import { StorageService } from 'src/app/_service/storage.service';
import { AuthService } from 'src/app/_service/auth.service';
import { Fornecedor } from 'src/app/_interface/fornecedor';

@Component({
  selector: 'app-fornecedores',
  templateUrl: './fornecedores.component.html',
  styleUrls: ['./fornecedores.component.scss']
})
export class FornecedoresComponent implements OnInit {

  fornecedores: any;
  valueUid: string;
  carregando = true;

  constructor(
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private afs: AngularFirestore,
    private storageService: StorageService,
    private authService: AuthService
  ) {
    this.valueUid = this.storageService.obterUid();
  }

  ngOnInit() {
    this.getFornecedoresAll();
  }

  adicionarFornecedor() {
    const dialogRef = this.dialog.open(AdicionarClienteFornecedorComponent, {
      data: 'fornecedor',
      panelClass: 'modalClassAdicionar'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.salvarDados(result)
    });
  }

  async salvarDados(result) {
    this.afs.collection("users").doc(this.valueUid).collection("listaFornecedores").add(
      {
        id: this.valueUid,
        nome: result.nome,
        telefone: result.telefone,
        cnpj_cpf: result.cnpj_cpf,
        nome_titular: result.nome_titular,
        banco: result.banco,
        agencia: result.agencia,
        conta: result.conta,
        created_at: new Date()
      }
    )
      .catch(e => {
        console.log('error', e);
      })
  }

  getFornecedoresAll() {
    this.afs.collection('users').doc(this.valueUid).collection('listaFornecedores').snapshotChanges().subscribe(resp => {
      this.fornecedores = resp.map(item => {
        return {
          value: item.payload.doc.id,
          ...item.payload.doc.data()
        } as unknown as Fornecedor
      })

      this.carregando = false
    }, error => {
      this.authService.errorFirebase(error);
      console.log(error)
    });
  }

  editarFornecedor(fornecedor) {
    const dialogRef = this.dialog.open(EditarClienteFornecedorComponent, {
      data: {
        tipo: 'fornecedor',
        cliente: fornecedor,
        opcao: 'editar'
      },
      panelClass: 'modalClassAdicionar'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.afs.collection("users").doc(this.valueUid).collection("listaFornecedores").doc(fornecedor.value).update({
        nome: result.nome,
        telefone: result.telefone,
        cnpj_cpf: result.cnpj_cpf,
        nome_titular: result.nome_titular,
        banco: result.banco,
        agencia: result.agencia,
        conta: result.conta,
        updated_at: new Date()
      });
    });
  }

  excluirFornecedor(fornecedor) {
    Swal.fire({
      title: 'Realmente deseja excluir?',
      text: "Após a confirmação, essa ação não poderá ser desfeita!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#F1B62B',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, tenho certeza!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {

        var message = 'Item excluído com sucesso!';
        var action = 'Ok';

        this.afs.collection("users").doc(this.valueUid).collection("listaFornecedores").doc(fornecedor.value).delete().catch(error => {
          console.log(error);
          message = 'Erro ao excluír item. Favor, tentar novamente!';
        })

        this._snackBar.open(message, action, {
          duration: 2000,
          panelClass: 'my-snack-bar'
        });


      }
    })
  }


  // Mascara para CPF e CNPJ
  isCPF(i): boolean {
    return this.fornecedores[i].cnpj_cpf == null ? true : this.fornecedores[i].cnpj_cpf.length < 12 ? true : false;
  }

  getCpfCnpjMask(i): string {
    return this.isCPF(i) ? '000.000.000-00' : '00.000.000/0000-00';
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-categoria-blog',
  templateUrl: './categoria-blog.component.html',
  styleUrls: ['./categoria-blog.component.scss']
})
export class CategoriaBlogComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

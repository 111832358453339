import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class DadosFixosService {

  constructor(private http: HttpClient) { }

  getStatusReceita(): Observable<any> {
    return Observable.create((x) => {
      return x.next([
        { value: 3, name: 'Recebido' },
        { value: 4, name: 'A Receber' }
      ])
    });
  }

  getStatusDespesa(): Observable<any> {
    return Observable.create((x) => {
      return x.next([
        { value: 1, name: 'Pago' },
        { value: 2, name: 'A Pagar' },
      ])
    });
  }

  getValor(): Observable<any> {
    return Observable.create((x) => {
      return x.next(20)
    });
  }



}

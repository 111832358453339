import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/_service/auth.service';
import { StorageService } from 'src/app/_service/storage.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  abaBloqueada = false;

  constructor(
    private authService: AuthService,
    private storageService: StorageService
  ) { }

  ngOnInit() {
    this.abaBloqueada = this.storageService.obterBloqueio();
  }

  sair() {
    this.authService.logout();
  }

}

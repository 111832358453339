import { Component, OnInit } from '@angular/core';
import { WordpressService } from 'src/app/_service/wordpress.service';
import { config } from 'src/config';
import { Router } from '@angular/router';

@Component({
  selector: 'app-listagem-blog',
  templateUrl: './listagem-blog.component.html',
  styleUrls: ['./listagem-blog.component.scss']
})
export class ListagemBlogComponent implements OnInit {

  listagemPostBlog: any[];
  page = 1;
  per_page = 12;
  total_page: any;
  carregando = true;

  constructor(
    private wordpressService: WordpressService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.buscarPosts(this.page);
  }

  buscarPosts(page) {
    this.wordpressService.obterTodosPosts(page, this.per_page).subscribe(resp => {
      this.listagemPostBlog = resp.map(x => {
        return this.popularPost(x);
      });

      this.obterImagem();
      this.descobrirPages();
      this.limitarResumo();

      this.carregando = false;
    }, error => {
      console.log(error)
    })

  }

  popularPost(x) {
    return {
      id: x.id,
      titulo: x.title.rendered,
      idImagemDestaque: x.featured_media,
      imagemDestaque: '',
      iDCategories: x.categories,
      categories: '',
      resumo: x.excerpt.rendered,
    };
  }

  obterImagem() {
    this.listagemPostBlog.forEach((i) => {
      this.wordpressService.obter_imagem_destaque(i.idImagemDestaque).subscribe((resp) => {
        var img = resp.media_details.file;
        i.imagemDestaque = config.caminhoUpload() + img;
        i.alt_text = resp.alt_text;
      });
    });
  }

  limitarResumo() {
    this.listagemPostBlog.forEach((i) => {
      i.resumo = i.resumo.substring(0, 250) + ' [...]';
    });
  }

  irPost(id, slug) {
    this.router.navigate(['/blog/post/'], {
      queryParams: {
        id: id,
        post: slug
      }
    });
  }
  
  anterior() {
    --this.page
    this.buscarPosts(this.page);
  }

  proxima() {
    ++this.page
    this.buscarPosts(this.page);
  }

  mudarPage(value) {
    this.page = value;
    this.buscarPosts(value);
  }

  paginacao: any[] = [{
    value: '',
    nome: '',
  }];

  descobrirPages() {
    var url = config.obterUrlAPI() + 'posts?per_page=' + this.per_page;

    var req = new XMLHttpRequest();
    req.open('GET', url, false);
    req.send(null);
    this.total_page = req.getResponseHeader('X-WP-TotalPages');

    this.numerandoPaginacao();
  }  

  numerandoPaginacao() {
    var contador = 0;
    var num = Number(this.total_page);
    var i: any;

    for (i = 0; i < num; i++) {
      this.paginacao[i] = [];
    }

    for (i = 0; i < num; i++) {
      contador = contador + 1;
      this.paginacao[i].value = String(contador);
      this.paginacao[i].nome = String(contador);
    }
  }
}

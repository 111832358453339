import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WordpressService } from 'src/app/_service/wordpress.service';
import Swal from 'sweetalert2';
import { config } from 'src/config';


@Component({
  selector: 'app-post-blog',
  templateUrl: './post-blog.component.html',
  styleUrls: ['./post-blog.component.scss']
})
export class PostBlogComponent implements OnInit {

  idPost: any;
  slugPost: any;
  page: any;

  conteudoPostBlog = {
    id: '',
    idImagemDestaque: '',
    imagemDestaque: '',
    titulo: '',
    idAutor: '',
    autor: '',
    data: '',
    horas: '',
    conteudo: '',
    slug: '',
    status: '',
    iDCategories: '',
    alt_text: ''
  }
  carregando = true;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private wordpressService: WordpressService,

  ) {
    this.route.queryParams.subscribe(params => {
      this.slugPost = params['post'];
      this.idPost = params['id'];
      this.mudarPost(this.idPost);
      this.descobindoPostAnteriorProximo();
    });   
  }

  ngOnInit() {
  }

  mudarPost(idPost) {
    this.wordpressService.obter_um_post(idPost).subscribe((resp) => {

      this.conteudoPostBlog.id = resp.id;
      this.conteudoPostBlog.idImagemDestaque = resp.featured_media; //id para acessar o media
      this.conteudoPostBlog.idAutor = resp.author;  //id para acessar o autor
      this.conteudoPostBlog.data = resp.date;
      this.conteudoPostBlog.titulo = resp.title.rendered;
      this.conteudoPostBlog.conteudo = resp.content.rendered;
      this.conteudoPostBlog.slug = resp.slug;
      this.conteudoPostBlog.status = resp.status;

      this.obterImagem(this.conteudoPostBlog.idImagemDestaque);

      

    }, error => {
      console.log(error);

      return Swal.fire({
        title: 'Não foi possível carregar o conteúdo',
        html: 'Favor tentar novamente'
      });
    });
  }

  obterImagem(idImagemDestaque) {
    this.wordpressService.obter_imagem_destaque(idImagemDestaque).subscribe((resp) => {
      var img = resp.media_details.file;
      this.conteudoPostBlog.imagemDestaque = config.caminhoUpload() + img;
      this.conteudoPostBlog.alt_text = resp.alt_text;

      this.carregando = false;
    });
  }

  chamarNovoPost(id, slug) {
    this.router.navigate(['/blog/post/'], {
      queryParams: {
        id: id,
        post: slug
      }
    });
  }

  anteriorPost: any;
  proximoPost: any;

  listagemPostBlog: any[];

  descobindoPostAnteriorProximo() {
    this.wordpressService.obterTodosPostsMesmo().subscribe(resp => {
      this.listagemPostBlog = resp.map(x => {
        return this.popularPost(x);
      });

      this.procurandoPost();

    }, error => {
      console.log(error)
    })
  }

  popularPost(x) {
    return {
      id: x.id,
      titulo: x.title.rendered
    };
  }

  procurandoPost() {
    this.listagemPostBlog.forEach((post, i) => {

      if (post.id == this.idPost) {
        const iNeg = i - 1;
        const iPos = i + 1;

        if(iNeg == -1){
          this.anteriorPost = null;
        } else {
          this.anteriorPost = this.listagemPostBlog[iNeg];
        }

        if(iPos == -1){
          this.proximoPost = null;
        } else {
          this.proximoPost = this.listagemPostBlog[iPos];
        }        
      }
    });
  }

  irPost(id, slug) {
    this.router.navigate(['/blog/post/'], {
      queryParams: {
        id: id,
        post: slug
      }
    });
  }

}

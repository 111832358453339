import { Component, OnInit } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { AddFiltroComponent } from '../modal/add-filtro/add-filtro.component';
import { AtualizacaoStatusComponent } from '../modal/atualizacao-status/atualizacao-status.component';
import { EditarReceitaDespesaComponent } from '../modal/editar-receita-despesa/editar-receita-despesa.component';
import Swal from 'sweetalert2';
import { AngularFirestore } from '@angular/fire/firestore';
import { RelatorioService } from 'src/app/_service/relatorio.service';
import { StorageService } from 'src/app/_service/storage.service';
import { AuthService } from 'src/app/_service/auth.service';

@Component({
  selector: 'app-fluxo-caixa',
  templateUrl: './fluxo-caixa.component.html',
  styleUrls: ['./fluxo-caixa.component.scss']
})
export class FluxoCaixaComponent implements OnInit {

  valueUid: string;
  dados: any[];
  dadosAnterior: any[];

  mesValue = [
    { value: 0, name: 'Janeiro' },
    { value: 1, name: 'Fevereiro' },
    { value: 2, name: 'Março' },
    { value: 3, name: 'Abril' },
    { value: 4, name: 'Maio' },
    { value: 5, name: 'Junho' },
    { value: 6, name: 'Julho' },
    { value: 7, name: 'Agosto' },
    { value: 8, name: 'Setembro' },
    { value: 9, name: 'Outubro' },
    { value: 10, name: 'Novembro' },
    { value: 11, name: 'Dezembro' },
  ]

  anoValue = [
    { value: 2020, name: '2020' },
    { value: 2021, name: '2021' },
    { value: 2022, name: '2022' },
  ]

  resultMes: number;
  resultAno: number;

  totalReceita: number;
  totalDespesa: number;
  progressDespesa = 100;
  progressReceita = 100;

  totalMesAtual: number;
  totalMesAnterior: number;

  dataInicialResumo: any;
  dataFinalResumo: any;
  dataInicial: any;
  dataFinal: any;

  dadosEmpresa: any;

  filterCategoria: any = [];
  filterCliente: any = [];
  filterFornecedor: any = [];

  carregando = true;

  constructor(
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private afs: AngularFirestore,
    private relatorioService: RelatorioService,
    private storageService: StorageService,
    private authService: AuthService
  ) {
    this.valueUid = this.storageService.obterUid();

    const current = new Date();
    this.resultMes = current.getUTCMonth();
    this.resultAno = current.getUTCFullYear();


    this.afs.collection('users').doc(this.valueUid).collection('geralUsuario').doc('usuario').valueChanges().subscribe(resp => {
      this.dadosEmpresa = resp
    }, error => {
      this.authService.errorFirebase(error);
      console.log(error);
    })

  }

  ngOnInit() {
    this.inicializandoDados()
  }

  inicializandoDados() {
    this.buscandoData();
    this.buscarDadosMesAtual();
    this.buscarDadosMesAnterior();
  }

  mudarCompetencia(value, campo) {

    if (campo == 'mes') {
      this.resultMes = value;

    } else if (campo == 'ano') {
      this.resultAno = value;
    }

    this.inicializandoDados();
  }

  buscandoData() {
    this.dataInicialResumo = new Date(this.resultAno, this.resultMes, 1);
    this.dataFinalResumo = new Date(this.resultAno, this.resultMes + 1, 0);
  }

  buscarDadosMesAtual() {
    const dataInicial = this.dataInicialResumo.getTime();
    const dataFinal = this.dataFinalResumo.getTime();

    this.afs.collection('users').doc(this.valueUid).collection('fluxo-caixa', ref => {

      return ref.where('data', '>=', dataInicial).where('data', '<=', dataFinal)

    }).snapshotChanges().subscribe(resp => {

      this.dados = resp.map(item => {
        return {
          id: item.payload.doc.id,
          ...item.payload.doc.data()
        }
      })

      this.dados.forEach(dados => {
        dados.data = new Date(dados.data);
        dados.valor = Number(dados.valor);
      })

      this.contadorResumoFinanceiro();

    }, error => {
      console.log(error)
    });
  }

  buscarDadosMesAnterior() {

    const dataInicial = new Date(this.resultAno, this.resultMes - 1, 1).getTime();
    const dataFinal = new Date(this.resultAno, this.resultMes, 0).getTime();

    this.afs.collection('users').doc(this.valueUid).collection('fluxo-caixa', ref => {

      return ref.where('data', '>=', dataInicial).where('data', '<=', dataFinal)

    }).snapshotChanges().subscribe(resp => {

      this.dadosAnterior = resp.map(item => {
        return {
          id: item.payload.doc.id,
          ...item.payload.doc.data()
        }
      })

      this.dados.forEach(dados => {
        dados.data = new Date(dados.data);
        dados.valor = Number(dados.valor);
      })

      this.valorTotalMesAnterior();

    }, error => {
      this.authService.errorFirebase(error);
      console.log(error)
    });
  }

  valorTotalMesAnterior() {
    this.totalMesAnterior = 0;

    this.dadosAnterior.forEach(dados => {
      if (dados.despesa_receita == 'despesa') {
        this.totalMesAnterior = this.totalMesAnterior - dados.valor
      }

      if (dados.despesa_receita == 'receita') {
        this.totalMesAnterior = this.totalMesAnterior + dados.valor
      }

    });

  }

  imprimir() {
    this.relatorioService.gerarPDF(this.dados, this.resultMes, this.resultAno, this.dadosEmpresa, this.totalReceita, this.totalDespesa, this.totalMesAtual);
  }

  addFiltro() {
    const dialogRef = this.dialog.open(AddFiltroComponent);

    dialogRef.afterClosed().subscribe(result => {

      result.categorias.forEach(dadosResult => {
        if (dadosResult.checked) {
          this.filterCategoria = this.dados.filter(x => {
            return x.categoria.nome == dadosResult.nome;
          });
        }
      });

      result.clientes.forEach(dadosResult => {
        if (dadosResult.checked) {
          this.filterCliente = this.dados.filter(x => {
            return x.cliente.nome == dadosResult.nome;
          });
        }
      });

      result.fornecedores.forEach(dadosResult => {
        if (dadosResult.checked) {
          this.filterFornecedor = this.dados.filter(x => {
            return x.cliente.nome == dadosResult.nome;
          });
        }
      });

      if (this.filterCategoria.length != 0 || this.filterCliente.length != 0 || this.filterFornecedor.length != 0) {
        this.dados = this.filterCategoria.concat(this.filterCliente, this.filterFornecedor);
      }

    });
  }

  atualizacaoStatus(dados) {
    const dialogRef = this.dialog.open(AtualizacaoStatusComponent, {
      data: dados
    });

    dialogRef.afterClosed().subscribe(result => {

      if (dados.despesa_receita == 'receita') {
        this.afs.collection('users').doc(this.valueUid).collection('fluxo-caixa').doc(dados.id).update({
          data: result.getTime(),
          status: {
            name: "Recebido",
            value: 3
          },
          updated_at: new Date()
        })

      } else {
        this.afs.collection('users').doc(this.valueUid).collection('fluxo-caixa').doc(dados.id).update({
          data: result.getTime(),
          status: {
            name: "Pago",
            value: 1
          },
          updated_at: new Date()
        })
      }

    }, error => {
      this.authService.errorFirebase(error);
      console.log(error)
    });
  }

  contadorResumoFinanceiro() {
    this.totalDespesa = 0;
    this.totalMesAtual = 0;
    this.totalReceita = 0;

    this.dados.forEach(dados => {

      if (dados.despesa_receita == 'despesa') {
        this.totalDespesa = this.totalDespesa + dados.valor;
        this.totalMesAtual = this.totalMesAtual - dados.valor;
      }

      if (dados.despesa_receita == 'receita') {
        this.totalReceita = this.totalReceita + dados.valor;
        this.totalMesAtual = this.totalMesAtual + dados.valor;
      }

    });

    this.calcularProgressResumo();
  }

  calcularProgressResumo() {
    if (this.totalDespesa > this.totalReceita) {
      this.progressReceita = (this.totalReceita * 100) / this.totalDespesa;
    }

    if (this.totalReceita > this.totalDespesa) {
      this.progressDespesa = (this.totalDespesa * 100) / this.totalReceita;
    }

    this.carregando = false
  }

  editarDados(dados) {
    const dialogRef = this.dialog.open(EditarReceitaDespesaComponent, {
      data: {
        tipo: dados.despesa_receita,
        dados: dados,
      },
      panelClass: 'modalClassAdicionar'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.afs.collection('users').doc(this.valueUid).collection('fluxo-caixa').doc(dados.id).update({
        data: result.data.getTime(),
        status: result.status,
        descricao: result.descricao,
        cliente: result.cliente,
        categoria: result.categoria,
        valor: result.valor,
        despesa_receita: result.despesa_receita,
        updated_at: new Date()
      })
    }, error => {
      this.authService.errorFirebase(error);
      console.log(error)
    });
  }

  excluirDados(dados) {
    Swal.fire({
      title: 'Realmente deseja excluir?',
      text: "Após a confirmação, essa ação não poderá ser desfeita!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#F1B62B',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, tenho certeza!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        var message = 'Item excluído com sucesso!';

        this.afs.collection('users').doc(this.valueUid).collection('fluxo-caixa').doc(dados.id).delete().catch(error => {
          this.authService.errorFirebase(error);
          console.log(error);
          message = 'Erro ao excluír item. Favor, tentar novamente!';
        })

        var action = 'Ok';

        this._snackBar.open(message, action, {
          duration: 2000,
          panelClass: 'my-snack-bar'
        });

        this.contadorResumoFinanceiro();
      }
    })
  }

}

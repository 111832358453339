import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-atualizacao-status',
  templateUrl: './atualizacao-status.component.html',
  styleUrls: ['./atualizacao-status.component.scss']
})
export class AtualizacaoStatusComponent implements OnInit {

  dataPagamento: any;
  validar = false;

  constructor(
    public thisDialogRef: MatDialogRef<AtualizacaoStatusComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() { }

  onCloseEnviar() {
    this.validar = true
    if (this.dataPagamento == null) {
      return
    }
    
    this.thisDialogRef.close(this.dataPagamento);
  }

}

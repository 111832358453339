import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { AngularFirestore } from '@angular/fire/firestore';
import { StorageService } from 'src/app/_service/storage.service';
import { AngularFireAuth } from '@angular/fire/auth';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/_service/auth.service';
import { AlterarSenhaComponent } from '../../modal/alterar-senha/alterar-senha.component';

@Component({
  selector: 'dados-pessoais',
  templateUrl: './dados-pessoais.component.html',
  styleUrls: ['./dados-pessoais.component.scss']
})
export class DadosPessoaisComponent implements OnInit {

  data: any = {
    usuario: {},
    empresa: {},
  };
  valueUid: string;

  emailAntigo: string;

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private storageService: StorageService,
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService
  ) {
    this.valueUid = this.storageService.obterUid();


    this.route.queryParams.subscribe(params => {
      if (!params) {
        this.router.navigate(['/redefinir-senha']);
      }
    });

  }

  ngOnInit() {
    this.getUsuario();
  }


  getUsuario() {
    this.afs.collection('users').doc(this.valueUid).collection('geralUsuario').doc('usuario').valueChanges().subscribe(resp => {
      this.data = resp;
    }, error => {
      this.authService.errorFirebase(error);
      console.log(error)
    })

    this.emailAntigo = this.data.usuario.email;
  }

  alterarSenha() {
    const dialogRef = this.dialog.open(AlterarSenhaComponent);

    dialogRef.afterClosed().subscribe(result => {

      if(result == ''){
        return
      }

      this.afAuth.auth.currentUser.updatePassword(result).then(resp => {
        Swal.fire({
          confirmButtonColor: '#F1B62B',
          icon: 'success',
          title: 'Senha salva!',
          text: 'Sua senha foi salva com sucesso!'
        })
      }).catch(error => {
        console.log('error', error);

        Swal.fire({
          confirmButtonColor: '#F1B62B',
          icon: 'error',
          title: 'Aconteceu algo de errado!',
          text: 'Sua senha não foi salva. Favor, tentar novamente!'
        })
      })
    });
  }

  salvarDados() {
    if (this.emailAntigo != this.data.usuario.email) {
      this.afAuth.auth.currentUser.updateEmail(this.data.usuario.email)
        .then(resp => {
          this.salvarInformacao(this.data.usuario.email)
        })
        .catch(error => {
          this.authService.errorFirebase(error);

          console.log('error', error)
          Swal.fire({
            confirmButtonColor: '#F1B62B',
            icon: 'error',
            title: 'Aconteceu algo de errado!',
            text: 'Seus dados não foram salvos. Favor, tentar novamente!'
          })
        })
    } else {
      this.salvarInformacao(this.emailAntigo)
    }
  }

  salvarInformacao(email) {
    this.afs.collection('users').doc(this.valueUid).collection('geralUsuario').doc('usuario').set({
      usuario: {
        email: email,
        displayName: this.data.usuario.displayName,
        updated_at: new Date()
      }, empresa: {
        cnpj: this.data.empresa.cnpj,
        nome: this.data.empresa.nome,
        telefone: this.data.empresa.telefone,
        email: this.data.empresa.email
      }
    }, { merge: true }).then(resp => {
      Swal.fire({
        confirmButtonColor: '#F1B62B',
        icon: 'success',
        title: 'Dados salvos!',
        text: 'Seus dados foram salvos com sucesso!'
      })

    }).catch(error => {
      this.authService.errorFirebase(error);

      Swal.fire({
        confirmButtonColor: '#F1B62B',
        icon: 'error',
        title: 'Aconteceu algo de errado!',
        text: 'Seus dados não foram salvos. Favor, tentar novamente!'
      })
    });
  }

}



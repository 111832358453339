import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { StorageService } from '../_service/storage.service';
import { AngularFirestore } from '@angular/fire/firestore';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-template-padrao',
  templateUrl: './template-padrao.component.html',
  styleUrls: ['./template-padrao.component.scss']
})
export class TemplatePadraoComponent implements OnInit {

  valueUid: any;
  vencimento: any;

  constructor(
    private ref: ChangeDetectorRef,
    private storageService: StorageService,
    private afs: AngularFirestore
  ) {
    this.valueUid = this.storageService.obterUid();
  }

  ngOnInit() {
    this.ref.detectChanges();
    window.scroll(0, 0);
    this.verificarPagamento()
  }

  verificarPagamento() {
    const data = new Date();
    const current = data.getTime();

    this.afs.collection('users').doc(this.valueUid).collection('geralUsuario').doc('usuario').valueChanges().subscribe(resp => {
      this.vencimento = resp;
      const venc = new Date(this.vencimento.usuario.expira_assinatura);
      const atual = new Date(current);
      
      if (atual < venc) {
        return
      } else {
        this.modalPlanoExpirado();
      }

    }, error => {
      console.log(error);
    })
  }

  modalPlanoExpirado() {
    this.storageService.salvarBloqueio(true);
    Swal.fire({
      confirmButtonColor: '#F1B62B',
      icon: 'error',
      title: 'Oops!',
      text: 'Devido a falta de pagamento de sua assinatura o serviço foi suspenso até novo pagamento.'
    })
  }

}

import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-adicionar-categoria',
  templateUrl: './adicionar-categoria.component.html',
  styleUrls: ['./adicionar-categoria.component.scss']
})
export class AdicionarCategoriaComponent implements OnInit {

  bancos: any;
  categoriaForm = {
    nome: null,
  }

  constructor(
    public thisDialogRef: MatDialogRef<AdicionarCategoriaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {

    if (this.data.categoria != null) {
      this.categoriaForm.nome = this.data.categoria.nome
    }

  }

  adicionarCategoria() {
    this.thisDialogRef.close(this.categoriaForm);
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { RodapeSiteComponent } from './rodape-site.component';

import { MatNativeDateModule } from '@angular/material';
import { AngularMaterialModule } from 'src/app/angular-material-module';

// Multi idioma
// https://www.codeandweb.com/babeledit/tutorials/how-to-translate-your-angular8-app-with-ngx-translate
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { HttpLoaderFactory } from 'src/app/app.module';

@NgModule({
  declarations: [
    RodapeSiteComponent
  ],
  imports: [
    CommonModule,
    RouterModule,

    AngularMaterialModule,
    MatNativeDateModule,

     // ngx-translate and the loader module
     HttpClientModule,
     TranslateModule.forRoot({
       loader: {
         provide: TranslateLoader,
         useFactory: HttpLoaderFactory,
         deps: [HttpClient]
       }
     })
  ],
  exports: [RodapeSiteComponent]
})
export class RodapeSiteModule {
}
import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'menu-site',
  templateUrl: './menu-site.component.html',
  styleUrls: ['./menu-site.component.scss']
})
export class MenuSiteComponent implements OnInit {

  bandeiraBR = true;
  bandeiraEN = false;

  @Input() logobranca;

  constructor(
    private translate: TranslateService
  ) {
  }

  ngOnInit() {
  }

  mudarPT() {
    this.translate.setDefaultLang('pt-BR');
    this.bandeiraBR = true;
    this.bandeiraEN = false;
  }

  mudarEN() {
    this.translate.setDefaultLang('en');
    this.bandeiraBR = false;
    this.bandeiraEN = true;
  }

}

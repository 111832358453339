import { Injectable } from '@angular/core';
import { NgZone } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class NavigateService {

  constructor(
    private ngZone: NgZone, 
    private router: Router
  ) { }

  public navigate(path: string){
    this.ngZone.run(() => this.router.navigate([path]))
    .then(x => console.log('x', x))
    .catch(error => console.log('error', error))
  }

}

import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-renovar-assinatura',
  templateUrl: './renovar-assinatura.component.html',
  styleUrls: ['./renovar-assinatura.component.scss']
})
export class RenovarAssinaturaComponent implements OnInit {

  constructor(
    public thisDialogRef: MatDialogRef<RenovarAssinaturaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
   
  }

}
